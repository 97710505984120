import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';

import {EModelType, EProjectEntity} from 'core/enums';
import {TProjectTaskModel} from 'stores/TechProcessStore';
import {
  TElementJsonSchemasModel,
  TElementModelJsonSchemasModel,
  TElementResultJsonSchemasModel
} from 'core/models';

import {
  ElementForms,
  ElementModelForms,
  ElementResultForms,
  SettingsForms,
  TaskForm
} from './components';

interface IProps {
  selectedType: EProjectEntity;
  selectedModelInstance?: TElementModelJsonSchemasModel;
  selectedElementInstance?: TElementJsonSchemasModel;
  selectedResultInstance?: TElementResultJsonSchemasModel;
  taskInstance: TProjectTaskModel;
  onChangeDefaultModel: (modelType: EModelType, modelId: string) => void;
  onResetCalculatedFields?: () => void;
}

const ProjectFormsFC: FC<IProps> = ({
  selectedType,
  selectedModelInstance,
  selectedElementInstance,
  selectedResultInstance,
  taskInstance,
  onChangeDefaultModel,
  onResetCalculatedFields
}) => {
  return (
    <Box p="12px 8px">
      {selectedType === EProjectEntity.Settings && <SettingsForms />}

      {selectedType === EProjectEntity.Element && !!selectedElementInstance && (
        <ElementForms
          key={selectedElementInstance.id}
          elementInstance={selectedElementInstance}
          onResetCalculatedFields={onResetCalculatedFields}
        />
      )}

      {selectedType === EProjectEntity.Model && !!selectedModelInstance && (
        <ElementModelForms
          key={selectedModelInstance.id}
          modelInstance={selectedModelInstance}
          onChangeDefaultModel={onChangeDefaultModel}
        />
      )}

      {selectedType === EProjectEntity.Task && <TaskForm taskInstance={taskInstance} />}

      {selectedType === EProjectEntity.Result && !!selectedResultInstance && (
        <ElementResultForms
          key={selectedResultInstance.id}
          resultInstance={selectedResultInstance}
        />
      )}
    </Box>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
