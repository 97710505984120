import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/rf-core';

import {EModelType} from 'core/enums';

export const UI_SCHEMA_COKER_MODEL_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelType: {
      'ui:field': '/schemas/jsf-select'
    }
  },
  connected_elements: {
    name_1: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_COKER_MODEL_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelType'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.CatCrackingElement, title: 'Реакторный блок УЗК'}],
          readOnly: true
        },
        modelType: {
          title: 'Тип модели',
          type: 'string',
          oneOf: [{const: EModelType.Coker, title: 'УЗК'}],
          readOnly: true
        }
      }
    },
    connected_elements: {
      title: 'Связанные элементы',
      type: 'object',
      properties: {
        name_1: {
          title: 'Реакторный блок УЗК',
          type: 'string',
          oneOf: [{const: '704F784D-0000-0000-0000-000000000001', title: 'COKER-100'}],
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA_COKER_MODEL_BASIC = {
  basic: {
    name: 'УЗК-1',
    elementType: EElement.CatCrackingElement,
    modelType: EModelType.Coker
  },
  connected_elements: {
    name_1: '704F784D-0000-0000-0000-000000000001'
  }
};
