import {memo} from 'react';
import {Box, Button, Flex, Grid, GridItem, IconButton} from '@chakra-ui/react';
import {IPureComponent, ILumpComponent} from '@progress-fe/core';

import {Svg} from '../../../../helpers';
import {InputNumber} from '../../../../inputs';

interface IProps {
  isDialog: boolean;
  isReadOnly?: boolean;
  formData: Record<string, Record<string, string | null>>;
  pureList: IPureComponent[];
  lumpList: ILumpComponent[];
  onCalcCoeffs: () => Promise<void>;
  onChangeValue: (rootKey: string, key: string, value: string | null) => void;
  onToggleDialog: () => void;
}

const BinaryCoeffsTableFC = ({
  isDialog,
  formData,
  pureList,
  lumpList,
  isReadOnly,
  onChangeValue,
  onCalcCoeffs,
  onToggleDialog
}: IProps) => {
  const rootKeys = Object.keys(formData);

  const getComponent = (uuid: string) =>
    pureList.find((c) => c.uuid === uuid) || lumpList.find((c) => c.uuid === uuid) || null;

  return (
    <Flex gap="8px" flexDirection="column">
      <Box p="4px" border="1px solid" borderRadius="4px" borderColor="border" overflowX="auto">
        {rootKeys.length === 0 && (
          <Flex p="8px 0 0 0" h="64px" alignItems="center" justifyContent="center">
            Нет компонентов
          </Flex>
        )}

        {/* TABLE HEADER */}
        <Grid p="2px 4px" gap="4px" gridTemplateColumns={`repeat(${rootKeys.length + 1}, 80px)`}>
          <GridItem />
          {rootKeys.map((rootKey) => (
            <GridItem key={rootKey}>{getComponent(rootKey)?.name || rootKey}</GridItem>
          ))}
        </Grid>

        {/* TABLE ROWS */}
        {rootKeys.map((rootKey) => (
          <Grid
            key={rootKey}
            p="2px 4px"
            gap="4px"
            alignItems="center"
            gridTemplateColumns={`repeat(${rootKeys.length + 1}, 80px)`}
          >
            {/* RAW NAME */}
            <GridItem>{getComponent(rootKey)?.name || rootKey}</GridItem>
            {/* RAW VALUES */}
            {rootKeys.map((key) => (
              <GridItem key={key}>
                <InputNumber
                  isFloat
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  disabled={isReadOnly || rootKey === key}
                  value={formData[rootKey][key] || undefined}
                  onChange={(value) => onChangeValue(rootKey, key, value)}
                />
              </GridItem>
            ))}
          </Grid>
        ))}
      </Box>

      <Grid gridTemplateColumns="1fr auto" gap="4px">
        <Button
          variant="ghost"
          size="2sm"
          width="100%"
          isDisabled={isReadOnly}
          onClick={onCalcCoeffs}
        >
          Рассчитать бинарные коэффициенты
        </Button>

        {!isDialog && (
          <IconButton
            size="2sm"
            aria-label=""
            variant="ghost"
            isDisabled={isReadOnly}
            icon={<Svg size="s12" name="Expand" />}
            onClick={onToggleDialog}
          />
        )}
      </Grid>
    </Flex>
  );
};

export const BinaryCoeffsTable = memo(BinaryCoeffsTableFC);
