import {useCallback, useEffect, useState} from 'react';
import {RegistryFieldsType, RJSFSchema, UiSchema} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import {observer} from 'mobx-react-lite';
import {Box} from '@chakra-ui/react';
import Form from '@rjsf/chakra-ui';
import {isObjectEmpty, IPureComponent, ILumpComponent, EComponentBaseType} from '@progress-fe/core';

import {
  TabsJsField,
  InputJsField,
  SelectJsField,
  NumberJsField,
  DividerJsField,
  NumberLockJsField,
  CheckboxJsField,
  ConnectionsJsField,
  BinaryCoeffsJsField,
  FlowCompositionJsField,
  SampleCompositionJsField,
  ProbeCurvesJsField,
  ProbeModelTableJsField,
  OilBlendModelJsField,
  TableJsField,
  CompressorCurveJsField
} from '../../jsFormFields';

import s from './JsForm.module.css';

export interface IJsFormBaseProps<T> {
  formData: T;
  schema: RJSFSchema;
  uiSchema?: UiSchema;
  onChange: (formData: T, changedFieldPath?: string) => void;
}

export interface IJsFormConfig {
  componentBaseType: EComponentBaseType;
  pureList: IPureComponent[];
  lumpList: ILumpComponent[];
  favoritePureList: IPureComponent[];
  favoriteLumpList: ILumpComponent[];
  onCalcBinaryCoeffs: () => Promise<Record<string, Record<string, string | null>>>;
}

export interface IJsFormProps<T> extends IJsFormBaseProps<T> {
  config: IJsFormConfig;
}

const JsFormFC = <T,>({config, schema, formData, uiSchema, onChange}: IJsFormProps<T>) => {
  const [jsFormFields, setJsFormFields] = useState<RegistryFieldsType>({});

  const rebuildJsFormFields = useCallback((): RegistryFieldsType => {
    return {
      '/schemas/jsf-checkbox': (props) => <CheckboxJsField {...props} />,
      '/schemas/jsf-input': (props) => <InputJsField {...props} />,
      '/schemas/jsf-number': (props) => <NumberJsField {...props} />,
      '/schemas/jsf-float': (props) => <NumberJsField isFloat {...props} />,
      '/schemas/jsf-number-lock': (props) => <NumberLockJsField {...props} />,
      '/schemas/jsf-float-lock': (props) => <NumberLockJsField isFloat {...props} />,
      '/schemas/jsf-select': (props) => <SelectJsField {...props} />,
      '/schemas/jsf-select-arrow': (props) => <SelectJsField {...props} isArrow />,
      '/schemas/jsf-select-pick-arrow': (props) => <SelectJsField {...props} isPick isArrow />,
      '/schemas/jsf-tabs': (props) => <TabsJsField {...props} />,
      '/schemas/jsf-divider': (props) => <DividerJsField {...props} />,
      '/schemas/jsf-probe-curves': (props) => <ProbeCurvesJsField {...props} />,
      '/schemas/jsf-sample-composition': (props) => <SampleCompositionJsField {...props} />,
      '/schemas/jsf-connections': (props) => <ConnectionsJsField {...props} />,
      '/schemas/jsf-binary-coeffs': (props) => (
        <BinaryCoeffsJsField
          {...props}
          lumpList={config.lumpList}
          pureList={config.pureList}
          onCalcBinaryCoeffs={config.onCalcBinaryCoeffs}
        />
      ),
      '/schemas/jsf-flow-composition': (props) => (
        <FlowCompositionJsField
          {...props}
          lumpList={config.lumpList}
          pureList={config.pureList}
          favoriteLumpList={config.favoriteLumpList}
          favoritePureList={config.favoritePureList}
          componentBaseType={config.componentBaseType}
        />
      ),
      '/schemas/jsf-probe-model-table': (props) => <ProbeModelTableJsField {...props} />,
      '/schemas/jsf-oil-blend-model': (props) => <OilBlendModelJsField {...props} />,
      '/schemas/jsf-table': (props) => <TableJsField {...props} />,
      '/schemas/jsf-compressor-curves': (props) => (
        <CompressorCurveJsField {...props} formData={props.formData} />
      )
    };
  }, [config]);

  useEffect(() => {
    // Defining of jsf-fields must be once (!), otherwise
    // it will cause "unmounting/mounting" jsf-fields after calling "onChange".
    if (isObjectEmpty(jsFormFields)) {
      setJsFormFields(rebuildJsFormFields());
    }
  }, [jsFormFields, rebuildJsFormFields]);

  return (
    <Box className={s.form}>
      {!isObjectEmpty(jsFormFields) && (
        <Form
          children
          liveValidate
          showErrorList={false}
          validator={validator}
          liveOmit={false} // Don't set "true". It will break custom "object" field types
          omitExtraData={false} // Don't set "true". It will break custom "object" field types
          schema={schema}
          formData={formData}
          uiSchema={uiSchema}
          fields={jsFormFields}
          onChange={(data, id) => onChange(data.formData as T, id)}
          onError={(errors) => console.info('JsForm error:', errors)}
          className={'js-form'}
        />
      )}
    </Box>
  );
};

export const JsForm = observer(JsFormFC);
