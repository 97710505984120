import {Edge, Node} from '@xyflow/react';
import {
  ERFElement,
  TRFEdgeDataConfig,
  TRFStructDataConfig,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS
} from '@progress-fe/rf-core';

export const STRUCTURE_DRAWING_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFStructDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000001', // V-100
        type: ERFElement.Separator2ph,
        data: {
          elementName: 'V-100',
          templateCode: 'default'
        },
        position: {x: 65, y: 340},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-1', // Hidden for Flow 1
        type: ERFElement.DotMaterial,
        data: {
          isSourceHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 5, y: 410},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-3', // Hidden for Flow 3
        type: ERFElement.DotMaterial,
        data: {
          isTargetHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 160, y: 550},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000002', // K-100
        type: ERFElement.Compressor,
        data: {
          elementName: 'K-100',
          templateCode: 'default'
        },
        position: {x: 140, y: 250},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Q-100', // Hidden for Q-100
        type: ERFElement.DotEnergy,
        data: {
          isSourceHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 220, y: 440},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000003', // E-100
        type: ERFElement.Cooler,
        data: {
          elementName: 'E-100',
          templateCode: 'default'
        },
        position: {x: 235, y: 140},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Q-101', // Hidden for Q-101
        type: ERFElement.DotEnergy,
        data: {
          isTargetHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 430, y: 100},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        type: ERFElement.Valve,
        data: {
          elementName: 'VLV-100',
          templateCode: 'default'
        },
        position: {x: 350, y: 200},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000005', // V-101
        type: ERFElement.Separator3ph,
        data: {
          elementName: 'V-101',
          templateCode: 'default'
        },
        position: {x: 420, y: 300},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-7', // Hidden for Flow 7
        type: ERFElement.DotMaterial,
        data: {
          isTargetHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 600, y: 180},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-8', // Hidden for Flow 8
        type: ERFElement.DotMaterial,
        data: {
          isTargetHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 600, y: 319},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-9', // Hidden for Flow 9
        type: ERFElement.DotMaterial,
        data: {
          isTargetHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 600, y: 460},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: '196C89E0-0000-0000-0000-000000000009',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // V-100
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        data: {
          elementName: '2'
        },
        sourceHandle: 'source_material_gas_flow',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000008',
        type: 'material',
        reconnectable: true,
        source: 'HIDDEN-Flow-1', // HIDDEN
        target: '196C89E0-0000-0000-0000-000000000001', // V-100
        data: {
          elementName: '1'
        },
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000010',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // V-100
        target: 'HIDDEN-Flow-3', // HIDDEN
        data: {
          elementName: '3'
        },
        sourceHandle: 'source_material_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000011',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000002', // K-100
        target: '196C89E0-0000-0000-0000-000000000003', // E-100
        data: {
          elementName: '4'
        },
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000006',
        type: 'energy',
        reconnectable: true,
        source: 'HIDDEN-Q-100', // HIDDEN
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        data: {
          elementName: 'Q-100'
        },
        targetHandle: 'target_energy_flow',
        ...RF_ENERGY_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000012',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000003', // E-100
        target: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        data: {
          elementName: '5'
        },
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000007',
        type: 'energy',
        reconnectable: true,
        target: 'HIDDEN-Q-101', // HIDDEN
        source: '196C89E0-0000-0000-0000-000000000003', // E-100
        data: {
          elementName: 'Q-101'
        },
        sourceHandle: 'source_energy_flow',
        ...RF_ENERGY_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000013',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000004', // VLV-100
        target: '196C89E0-0000-0000-0000-000000000005', // V-101
        data: {
          elementName: '6'
        },
        sourceHandle: 'source_material_flow',
        targetHandle: 'target_material_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000014',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: 'HIDDEN-Flow-7', // HIDDEN
        data: {
          elementName: '7'
        },
        sourceHandle: 'source_material_gas_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000015',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: 'HIDDEN-Flow-8', // HIDDEN
        data: {
          elementName: '8'
        },
        sourceHandle: 'source_material_light_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-000000000016',
        type: 'material',
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000005', // V-101
        target: 'HIDDEN-Flow-9', // HIDDEN
        data: {
          elementName: '9'
        },
        sourceHandle: 'source_material_heavy_liquid_flow',
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    nodes: [
      {
        id: '704F784D-0000-0000-0000-000000000001', // COKER-100
        type: ERFElement.Coker,
        data: {
          elementName: 'COKER-100',
          templateCode: 'default'
        },
        position: {x: 320, y: 155},
        connectable: true,
        deletable: true
      },
      {
        id: 'HIDDEN-Flow-1', // Hidden for 1
        type: ERFElement.DotMaterial,
        data: {
          isSourceHandle: true,
          elementName: '',
          templateCode: 'default'
        },
        position: {x: 120, y: 286},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: '704F784D-0000-0000-0000-000000000002',
        type: 'material',
        reconnectable: true,
        source: 'HIDDEN-Flow-1', // HIDDEN
        target: '704F784D-0000-0000-0000-000000000001', // COKER-100
        data: {
          elementName: '1'
        },
        targetHandle: 'XXX', //TODO: createPortCode('material', 'target', 1),
        ...RF_MATERIAL_EDGE_PROPS,
        deletable: true
      }
    ]
  }
];
