import {flow, Instance, types} from 'mobx-state-tree';
import {EventEmitter} from '@progress-fe/core';

import {ProjectTypeEnum} from 'api';
import {ProjectBase, TProjectEntityJsonSchemasModel} from 'core/models';

import {ProjectProbes} from './models/ProjectProbes/ProjectProbes';
import {ProjectBlends} from './models/ProjectBlends/ProjectBlends';

const SamplesAndOilStore = types
  .compose(
    ProjectBase,
    types.model('SamplesAndOilProject', {
      type: types.optional(
        types.literal(ProjectTypeEnum.SamplesAndOilBlend),
        ProjectTypeEnum.SamplesAndOilBlend
      ),
      projectProbes: types.optional(ProjectProbes, {}),
      projectBlends: types.optional(ProjectBlends, {})
    })
  )
  .actions((self) => ({
    _selectEntity() {
      throw Error('there is no implementation');
    },
    uninitialized() {
      self.resetModel();
      EventEmitter.off('SelectEntity', this._selectEntity);
    }
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;

      const project = yield self.getProjectInfo(projectId);

      if (!project) {
        return;
      }

      self._baseInit(project);

      EventEmitter.on('SelectEntity', self._selectEntity);

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get selectedProbe(): TProjectEntityJsonSchemasModel | null {
      return self.projectProbes.probes.find((el) => el.id === self.uiState.entityId) || null;
    },

    get selectedBlend(): TProjectEntityJsonSchemasModel | null {
      return self.projectBlends.blends.find((el) => el.id === self.uiState.entityId) || null;
    }
  }));

export type TSamplesAndOilStore = Instance<typeof SamplesAndOilStore>;

export {SamplesAndOilStore};
