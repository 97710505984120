import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid, Text} from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {ComponentsLists} from '@progress-fe/ui-kit';
import {EComponentBaseType} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {SelectedComponents} from './components';

interface IProps {}

const ProjectComponentsFC: FC<IProps> = () => {
  const {catalogsStore} = useStore();
  const {pureComponentsCatalog} = catalogsStore;

  return (
    <Grid gridTemplateRows="2fr 40px 1fr" h="calc(100vh - 130px)">
      {/* CHEMICAL COMPONENTS DICTIONARY */}
      <Box height="100%">
        <AutoSizer>
          {({height, width}) => (
            <Box width={width} pt="16px">
              <ComponentsLists
                title={'Компоненты'}
                defaultComponentType={EComponentBaseType.PURE}
                allPureComponents={pureComponentsCatalog.components}
                allLumpComponents={[]}
                selectedPureComponents={[]}
                selectedLumpComponents={[]}
                onAddPureComponent={() => {}}
                onAddLumpComponent={() => {}}
                onAddAllPureComponents={() => {}}
                onAddAllLumpComponents={() => {}}
                onDeletePureComponent={() => {}}
                onDeleteLumpComponent={() => {}}
                onDeleteAllPureComponents={() => {}}
                onDeleteAllLumpComponents={() => {}}
                tableSx={{height: height - 106}}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>

      <Text p="16px 0 8px 0" fontSize="12px" fontWeight={500}>
        Выбранные
      </Text>

      {/* SELECTED CHEMICAL COMPONENTS */}
      <Box height="100%">
        <AutoSizer>
          {({height, width}) => (
            <Box width={width}>
              <SelectedComponents pureComponents={[]} lumpComponents={[]} tableSx={{height}} />
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Grid>
  );
};

export const ProjectComponents = observer(ProjectComponentsFC);
