import {FC, Fragment, memo, useEffect, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {
  Divider,
  Flex,
  Button,
  Center,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Grid,
  VStack
} from '@chakra-ui/react';
import {v4 as uuidv4} from 'uuid';
import {useT} from '@progress-fe/core';

import {TCompressorCurve, TCompressorCurveFormData} from './CompressorCurvesFormData.types';
import {CompressorCurve} from './components';
import {ICurveErrors} from './CurveErrors.interface';

const checkCurveValidity = (
  curve: TCompressorCurve,
  existedCurves: TCompressorCurve[]
): ICurveErrors | null => {
  const checkName = curve.name.toLowerCase();
  const isNotUniqName = existedCurves.some(
    (c) => c.id !== curve.id && c.name.toLowerCase() === checkName
  );

  if (isNotUniqName) {
    return {
      isNameInvalid: true
    };
  }

  return null;
};

interface IProps extends FieldProps {
  formData?: TCompressorCurveFormData;
}

const CompressorCurvesJsFieldFC: FC<IProps> = ({formData, schema, onChange}) => {
  const {t} = useT();
  const [curvesErrors, setCurvesErrors] = useState<Record<string, ICurveErrors>>({});
  const [validCurves, setValidCurves] = useState<TCompressorCurve[]>([]);

  const fieldValues = formData?.fieldValues || [];
  const fieldConfig = formData?.fieldConfig || {
    flowTypeOptions: [],
    pressureTypeOptions: []
  };

  useEffect(() => {
    if (!formData?.fieldValues) {
      return;
    }

    const allErrors = formData.fieldValues.reduce<Record<string, ICurveErrors>>(
      (acc: Record<string, ICurveErrors>, current: TCompressorCurve) => {
        const errors = checkCurveValidity(current, formData.fieldValues);

        if (errors) {
          acc[current.id] = errors;
        }

        return acc;
      },
      {}
    );

    setCurvesErrors(allErrors);

    const valid = formData.fieldValues.filter((item) => !!item.name && !allErrors[item.id]);
    setValidCurves(valid);
  }, [formData?.fieldValues]);

  const handleAddCurve = () => {
    onChange({
      ...formData,
      fieldValues: [
        ...fieldValues,
        {
          id: uuidv4(),
          name: '',
          values: [
            {
              efficiency: '',
              flow: '',
              pressure: ''
            }
          ]
        }
      ]
    });
  };

  const handleUpdateCurve = (updatedCurve: TCompressorCurve) => {
    const errors = checkCurveValidity(updatedCurve, fieldValues);

    if (errors) {
      setCurvesErrors((map) => ({...map, [updatedCurve.id]: errors}));
      return;
    }

    if (curvesErrors[updatedCurve.id]) {
      setCurvesErrors((map) => {
        const newMap = {...map};

        delete newMap[updatedCurve.id];

        return newMap;
      });
    }

    const newCurves = fieldValues.map(c => c.id === updatedCurve.id ? {...updatedCurve} : c);

    onChange({
      ...formData,
      fieldValues: newCurves
    });
  };

  const handleDeleteCurve = (curve: TCompressorCurve) => {
    const newCurves = fieldValues.filter(c => c.id !== curve.id);

    onChange({
      ...formData,
      fieldValues: newCurves
    });
  };

  const handleCopyCurve = (baseCurve: TCompressorCurve) => {
    const newCurves = [...fieldValues];

    newCurves.push({...baseCurve, id: uuidv4(), name: `Копия ${baseCurve.name}`});

    onChange({
      ...formData,
      fieldValues: newCurves
    });
  };

  return (
    <Flex flexDirection="column" gap="8px">
      {/* No items */}
      {fieldValues.length === 0 && (
        <Center h="70px">
          <Text> {t('jsfields.CompressorCurve.noCurves')}</Text>
        </Center>
      )}
      {/* Curves list */}
      {!!fieldValues.length && (
        <VStack overflowY="auto" maxH="500px" gap="8px">
          {fieldValues.map((curve, idx) => {
            return (
              <Fragment key={curve.id}>
                <CompressorCurve
                  isReadonly={!!schema.readOnly}
                  fieldConfig={fieldConfig}
                  curve={curve}
                  errors={curvesErrors[curve.id]}
                  onChange={(updatedCurve) => {
                    handleUpdateCurve(updatedCurve);
                  }}
                  onDelete={() => {
                    handleDeleteCurve(curve);
                  }}
                />
                {idx !== fieldValues.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </VStack>
      )}

      {/* Actions */}
      {!schema.readOnly && (
        <>
          <Divider />
          <Grid gap="8px" w="100%" gridTemplateColumns="1fr 1fr">
            <Button variant="ghost" size="2sm" w="100%" onClick={handleAddCurve}>
              {t('jsfields.CompressorCurve.addCurve')}
            </Button>
            <Menu matchWidth offset={[0, 2]} variant="outline">
              <MenuButton
                size="xs"
                as={Button}
                width="100%"
                variant="ghost"
                isDisabled={!validCurves.length}
              >
                {t('jsfields.CompressorCurve.copyCurve')}
              </MenuButton>

              <MenuList motionProps={{animate: false}}>
                {validCurves.map((curve) => (
                  <MenuItem key={curve.id} onClick={() => handleCopyCurve(curve)}>
                    {curve.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Grid>
        </>
      )}
    </Flex>
  );
};

export const CompressorCurveJsField = memo(CompressorCurvesJsFieldFC);
