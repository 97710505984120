import {FC} from 'react';
import {Box, Center, Text} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EntityHeader} from '@progress-fe/ui-kit';
import {TRFSvgName} from '@progress-fe/rf-core';

import {EntityItem} from 'ui-kit';
import {EModelType} from 'core/enums';
import {TElementModelJsonSchemasModel} from 'core/models';
import {MODELS_OF_ELEMENTS_ITEMS} from 'core/constants';

interface IProps {
  models: TElementModelJsonSchemasModel[];
  selectedId: string | null;
  onSelect: (modelId: string) => void;
  onCreateModel: (type: EModelType) => void;
}

const ElementModelListFC: FC<IProps> = ({models, selectedId, onSelect, onCreateModel}) => {
  return (
    <Box overflowY="hidden">
      <EntityHeader
        title="Модели"
        dropdownItems={MODELS_OF_ELEMENTS_ITEMS}
        onSelectItem={(modelType) => onCreateModel(modelType as EModelType)}
      />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {models.map((m) => (
          <EntityItem
            key={m.id}
            name={m.namePropValue}
            elementIcon={m.icon as TRFSvgName}
            isActive={selectedId === m.id}
            onClick={() => onSelect(m.id)}
          />
        ))}

        {models.length === 0 && (
          <Center pb="40px" height="100%">
            <Text>Нет моделей</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export const ElementModelList = observer(ElementModelListFC);
