/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RJFSchemas
 */
export interface RJFSchemas {
    /**
     * 
     * @type {object}
     * @memberof RJFSchemas
     */
    ui: object;
    /**
     * 
     * @type {object}
     * @memberof RJFSchemas
     */
    jsonSchema: object;
    /**
     * 
     * @type {object}
     * @memberof RJFSchemas
     */
    data: object;
}

/**
 * Check if a given object implements the RJFSchemas interface.
 */
export function instanceOfRJFSchemas(value: object): value is RJFSchemas {
    if (!('ui' in value) || value['ui'] === undefined) return false;
    if (!('jsonSchema' in value) || value['jsonSchema'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function RJFSchemasFromJSON(json: any): RJFSchemas {
    return RJFSchemasFromJSONTyped(json, false);
}

export function RJFSchemasFromJSONTyped(json: any, ignoreDiscriminator: boolean): RJFSchemas {
    if (json == null) {
        return json;
    }
    return {
        
        'ui': json['ui'],
        'jsonSchema': json['json_schema'],
        'data': json['data'],
    };
}

export function RJFSchemasToJSON(value?: RJFSchemas | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ui': value['ui'],
        'json_schema': value['jsonSchema'],
        'data': value['data'],
    };
}

