import {applySnapshot, cast, flow, getSnapshot, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {IErrorMessage} from '@progress-fe/ui-kit';
import {LocalStorageHelper, isObject} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, ProjectInfo} from 'core/models';
import {ProjectOut, ProjectsApi, ProjectTypeEnum} from 'api';
import {EModuleType} from 'core/enums';
import {PROJECT_LIST} from 'core/mocks/projects.mocks';

import {History, THistorySnapshotIn, UiState} from './models';

const ProjectBase = types
  .compose(
    ResetModel,
    types.model('ProjectBase', {
      // type is overided in child models
      type: types.optional(
        types.enumeration(Object.values(ProjectTypeEnum)),
        ProjectTypeEnum.UnknownDefaultOpenApi
      ),
      isLoading: false,
      history: types.optional(History, {}),
      activeHistoryIndex: 0,
      projectInfo: types.maybeNull(ProjectInfo),
      isResettingFieldsNeeded: false,
      uiState: types.optional(UiState, {}),
      errors: types.optional(types.array(types.frozen<IErrorMessage>()), []),
      isNotFound: false
    })
  )
  .actions((self) => ({
    _baseInit(project: ProjectOut): void {
      self.history.init(project.uuid);

      self.projectInfo = ProjectInfo.create({
        id: project.uuid,
        author: 'Иван Метанов',
        type: project.type,
        name: project.name,
        modified: project.updatedAt.toISOString(),
        // TODO: looks like useless property in MVP 2
        moduleType: EModuleType.SEPARATOR
      });

      self._initHistoryFromLocalStorage(project.uuid);
    },
    _setErrors(errors: IErrorMessage[]): void {
      self.errors = cast(errors);
    }
  }))
  .actions((self) => ({
    _initHistoryFromLocalStorage(projectId: string): void {
      const name = `progress:project_${projectId}`;
      const storage = new LocalStorageHelper<THistorySnapshotIn>(name, isObject);
      const historyFromStorage = storage.getNullable();

      if (!historyFromStorage) {
        return;
      }

      applySnapshot(self.history, historyFromStorage);
    },
    saveHistory() {
      const currentHistoryItem = self.history.getHistoryItemByIndex(self.activeHistoryIndex);
      if (!currentHistoryItem) {
        return;
      }

      self.history.updateSaveDate();

      const name = `progress:project_${self.projectInfo?.id}`;
      const storage = new LocalStorageHelper(name, isObject);
      const snapshot = getSnapshot(self.history);
      storage.store(snapshot);
    },
    createHistoryAndSwitch(): void {
      self.history.historyItems.push(cast({id: uuidv4()}));
      self.activeHistoryIndex = self.history.historyCount - 1;
    },
    switchToHistory(index: number): void {
      const nextHistoryItem = self.history.getHistoryItemByIndex(index);
      const currentHistoryItem = self.history.getHistoryItemByIndex(self.activeHistoryIndex);

      if (!nextHistoryItem || !currentHistoryItem) {
        return;
      }

      self.activeHistoryIndex = index;
    }
  }))
  .actions((self) => ({
    getProjectInfo: flow(function* (projectId: string) {
      //TODO: me have to use project info endpoint instead of trying to find project from a list
      const checkInMock = PROJECT_LIST.find((project) => project.id === projectId);
      if (checkInMock) {
        self.isNotFound = checkInMock.type !== self.type;
        return {
          uuid: checkInMock.id,
          updatedAt: new Date(checkInMock.modified),
          deleted: false,
          isFavorite: true,
          ...checkInMock
        };
      }

      const projects: ProjectOut[] = yield ProjectsApi.projectsGetProjects().then(
        (data) => data.items || []
      );

      const checkInResponse = projects.find((project) => project.uuid === projectId);
      self.isNotFound = checkInResponse?.type !== self.type;

      if (!checkInResponse) {
        self.isLoading = false;
      }

      return checkInResponse ?? null;
    })
  }))
  .views(() => ({
    get isCalculating(): boolean {
      return false;
    }
  }));

export type TProjectBaseModel = Instance<typeof ProjectBase>;

export type TProjectBaseModelSnapshotIn = SnapshotIn<typeof ProjectBase>;

export {ProjectBase};
