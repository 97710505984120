import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema, ElementResultInfo} from 'core/models/index';
import {CalculationResultItem} from 'core/models/CalculationResultItem';

const ElementResultJsonSchemas = types
  .compose(
    ElementResultInfo,
    types.model('ElementResultJsonSchemas', {
      jsonSchemas: types.optional(types.array(JsonSchema), []),
      calculationData: types.maybeNull(CalculationResultItem)
    })
  )
  .actions((self) => ({
    clearCalculationData(): void {
      self.calculationData = null;
    }
  }))
  .views((self) => ({
    get namePropValue(): string {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.name || ''
        : '';
    }
  }));

export type TElementResultJsonSchemasModel = Instance<typeof ElementResultJsonSchemas>;

export type TElementResultJsonSchemasSnapshotIn = SnapshotIn<typeof ElementResultJsonSchemas>;

export {ElementResultJsonSchemas};
