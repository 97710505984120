export enum EModelType {
  Robinson = 'Robinson',
  Separator_2ph = 'Separator_2ph',
  Separator_3ph = 'Separator_3ph',
  Compressor = 'Compressor',
  Cooler = 'Cooler',
  Coker = 'Coker',
  Valve = 'Valve',
  CPA = 'CPA',
  NRTL = 'NRTL',
  Glycols = 'Glycols',
  Amines = 'Amines',
  SRK = 'SRK'
}
