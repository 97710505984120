import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema} from 'core/models/index';

const ProjectEntityJsonSchemas = types
  .model('ProjectEntityJsonSchemas', {
    id: types.string,
    name: types.string,
    jsonSchemas: types.optional(types.array(JsonSchema), [])
  })
  .actions(() => ({}));

export type TProjectEntityJsonSchemasModel = Instance<typeof ProjectEntityJsonSchemas>;

export type TProjectEntityJsonSchemasSnapshotIn = SnapshotIn<typeof ProjectEntityJsonSchemas>;

export {ProjectEntityJsonSchemas};
