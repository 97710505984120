import {Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

import {CalculationDrawing, StructureDrawing} from './models';

const ProjectDrawing = types
  .compose(
    ResetModel,
    types.model('ProjectDrawing', {
      structureDrawing: types.optional(StructureDrawing, {}),
      calculationDrawing: types.optional(CalculationDrawing, {})
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      self.structureDrawing.fetch(projectId);
      self.calculationDrawing.fetch(projectId);
    }
  }))
  .views(() => ({}));

export type TProjectDrawingModel = Instance<typeof ProjectDrawing>;

export {ProjectDrawing};
