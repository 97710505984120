import {cast, Instance, types} from 'mobx-state-tree';

import {RESULTS_LIST} from 'core/mocks/results.mocks';
import {ElementResultJsonSchemas, ResetModel} from 'core/models';

const ProjectElementResults = types
  .compose(
    ResetModel,
    types.model('ProjectResults', {
      elementsSchemasResults: types.optional(types.array(ElementResultJsonSchemas), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const results = RESULTS_LIST.find((m) => m.projectId === projectId)?.items || [];
      self.elementsSchemasResults = cast(results);
    },
    hasResult(resultId: string) {
      return self.elementsSchemasResults.some((r) => r.id === resultId);
    },
    clearAllCalculationData(): void {
      self.elementsSchemasResults.forEach((item) => {
        item.clearCalculationData();
      });
    }
  }))
  .views(() => ({}));

export type TProjectElementResultsModel = Instance<typeof ProjectElementResults>;

export {ProjectElementResults};
