import {FC, ReactNode, useCallback, useState} from 'react';
import {Box, Center, IconButton, Portal} from '@chakra-ui/react';

import {Svg} from '../Svg';

import {DialogWindow} from './components';

interface IProps {
  title: string;
  children?: ReactNode;
  isFloating?: boolean;
  minWidth?: string;
  floatMinWidth?: string;
  onClose?: () => void;
}

const DialogFC: FC<IProps> = ({title, children, isFloating, minWidth, floatMinWidth, onClose}) => {
  const [isFloat, setIsFloat] = useState(false);

  const hadleToggleFloat = useCallback(() => {
    setIsFloat((prev) => !prev);
  }, []);

  if (isFloat) {
    return (
      <Portal>
        <DialogWindow
          right="0"
          bottom="0"
          zIndex={10}
          position="absolute"
          maxW="30%"
          minW={floatMinWidth}
          title={title}
          onClose={onClose}
          actionElement={
            isFloating ? (
              <IconButton
                size="2sm"
                aria-label=""
                variant="ghostTr"
                icon={<Svg size="s12" name="Max" />}
                onClick={hadleToggleFloat}
              />
            ) : undefined
          }
        >
          {children}
        </DialogWindow>
      </Portal>
    );
  }

  return (
    <Portal>
      <Box
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex={1}
        position="absolute"
        bg="rgba(255, 255, 255, 0.5)"
      >
        <Center h="100%" w="100%">
          <DialogWindow
            maxW="80%"
            minW={minWidth}
            title={title}
            onClose={onClose}
            actionElement={
              isFloating ? (
                <IconButton
                  size="2sm"
                  aria-label=""
                  variant="ghostTr"
                  icon={<Svg size="s12" name="Min" />}
                  onClick={hadleToggleFloat}
                />
              ) : undefined
            }
          >
            {children}
          </DialogWindow>
        </Center>
      </Box>
    </Portal>
  );
};

export const Dialog = DialogFC;
