import {IErrorMessage} from '@progress-fe/ui-kit';
import {Instance, SnapshotIn, types} from 'mobx-state-tree';

const HistoryItem = types.model('HistoryItem', {
  id: types.identifier,
  errors: types.optional(types.array(types.frozen<IErrorMessage>()), [])
});

export type THistoryItemModel = Instance<typeof HistoryItem>;

export type THistoryItemSnapshotIn = SnapshotIn<typeof HistoryItem>;

export {HistoryItem};
