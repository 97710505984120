import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EElementResultType} from 'core/enums';

const ElementResultInfo = types
  .model('ElementResultInfo', {
    id: types.string,
    name: types.string,
    element_id: types.string,
    type: types.enumeration(Object.values(EElementResultType)),
    icon: types.string
  })

  .actions(() => ({}))
  .views(() => ({}));

export type TElementResultInfoModel = Instance<typeof ElementResultInfo>;

export type TElementResultInfoSnapshotIn = SnapshotIn<typeof ElementResultInfo>;

export {ElementResultInfo};
