import {FC, PropsWithChildren, ReactElement} from 'react';
import {Box, Center, Grid, GridItem} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {Loader} from '@progress-fe/ui-kit';

import {TProjectBaseModel} from 'core/models';
import {useStore} from 'core';

import {HistorySideBar, ProjectMainBar} from './components';

export interface IProjectLayoutProps extends PropsWithChildren {
  project: TProjectBaseModel;
  projectActions?: ReactElement;
}

const ProjectLayoutFC: FC<IProjectLayoutProps> = ({project, projectActions, children}) => {
  const {authStore} = useStore();
  const {history, projectInfo, errors} = project;

  if (project.isLoading || !projectInfo) {
    return (
      <Center h="100vh" bg="darkWhite">
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      {!!projectInfo && authStore.user && (
        <ProjectMainBar
          title={projectInfo.name}
          currentUser={authStore.user}
          errors={errors}
          isCalculating={project.isCalculating}
          lastSaveDate={history.lastSaveDate}
          additionalActions={projectActions}
          onSave={project.saveHistory}
          onSignOut={authStore.signOut}
          onRunCalculation={async () => {
            if (!(await project.runCalculation())) {
              project.resetCalculatedFields();
            }
          }}
        />
      )}

      {project.isCalculating && <Loader fullScreen />}

      <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'48px 1fr'}>
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <HistorySideBar
            historyCount={history.historyCount}
            activeHistoryIndex={project.activeHistoryIndex}
            onSwitchToHistory={project.switchToHistory}
            onCreateHistoryAndSwitch={project.createHistoryAndSwitch}
          />
        </GridItem>
        <GridItem>{children}</GridItem>
      </Grid>
    </Box>
  );
};

export const ProjectLayout = observer(ProjectLayoutFC);
