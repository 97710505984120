import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {ElementInfo, JsonSchema} from 'core/models/index';

const ElementJsonSchemas = types
  .compose(
    ElementInfo,
    types.model('ElementJsonSchemas', {
      jsonSchemas: types.optional(types.array(JsonSchema), [])
    })
  )
  .actions(() => ({}))
  .views((self) => ({
    get namePropValue(): string {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.name || ''
        : '';
    },
    get elementTypePropValue(): string {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.elementType || ''
        : '';
    },
    get elementModelIdPropValue(): string | null {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.modelId || null
        : null;
    }
  }));

export type TElementJsonSchemasModel = Instance<typeof ElementJsonSchemas>;

export type TElementJsonSchemasSnapshotIn = SnapshotIn<typeof ElementJsonSchemas>;

export {ElementJsonSchemas};
