import {ISvgMenuItem} from '@progress-fe/ui-kit';
import {i18n} from '@progress-fe/core';
import {EElement} from '@progress-fe/rf-core';

import {EModelType} from 'core/enums';

export const MODELS_OF_ELEMENTS_ITEMS: ISvgMenuItem<string>[] = [
  {
    id: EElement.MaterialFlowElement,
    name: i18n.t(`enum.element.long.${EElement.MaterialFlowElement}`),
    items: [
      {
        id: EModelType.Robinson,
        name: i18n.t(`enum.modelType.long.${EModelType.Robinson}`)
      },
      {
        id: EModelType.CPA,
        name: i18n.t(`enum.modelType.long.${EModelType.CPA}`),
        isDisabled: true
      },
      {
        id: EModelType.NRTL,
        name: i18n.t(`enum.modelType.long.${EModelType.NRTL}`),
        isDisabled: true
      },
      {
        id: EModelType.Glycols,
        name: i18n.t(`enum.modelType.long.${EModelType.Glycols}`),
        isDisabled: true
      },
      {
        id: EModelType.Amines,
        name: i18n.t(`enum.modelType.long.${EModelType.Amines}`),
        isDisabled: true
      },
      {
        id: EModelType.SRK,
        name: i18n.t(`enum.modelType.long.${EModelType.SRK}`),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.TwoPhaseSeparatorElement,
    name: i18n.t(`enum.element.long.${EElement.TwoPhaseSeparatorElement}`),
    items: [
      {
        id: EModelType.Separator_2ph,
        //name: i18n.t(`enum.modelType.long.${EModelType.Separator_2ph}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.ThreePhaseSeparatorElement,
    name: i18n.t(`enum.element.long.${EElement.ThreePhaseSeparatorElement}`),
    items: [
      {
        id: EModelType.Separator_3ph,
        //name: i18n.t(`enum.modelType.long.${EModelType.Separator_3ph}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.CatCrackingElement,
    name: i18n.t(`enum.element.long.${EElement.CatCrackingElement}`),
    items: [
      {
        id: EModelType.Coker,
        //name: i18n.t(`enum.modelType.long.${EModelType.Coker}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.CompressorElement,
    name: i18n.t(`enum.element.long.${EElement.CompressorElement}`),
    items: [
      {
        id: EModelType.Compressor,
        //name: i18n.t(`enum.modelType.long.${EModelType.Compressor}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.CoolerElement,
    name: i18n.t(`enum.element.long.${EElement.CoolerElement}`),
    items: [
      {
        id: EModelType.Cooler,
        //name: i18n.t(`enum.modelType.long.${EModelType.Cooler}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  },
  {
    id: EElement.ValveElement,
    name: i18n.t(`enum.element.long.${EElement.ValveElement}`),
    items: [
      {
        id: EModelType.Valve,
        //name: i18n.t(`enum.modelType.long.${EModelType.Valve}`),
        name: i18n.t('common.simple'),
        isDisabled: true
      }
    ]
  }
];
