import {cast, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

import {HistoryItem, THistoryItemModel} from './models';

const History = types
  .compose(
    ResetModel,
    types.model('History', {
      projectId: '',
      lastSaveDate: types.maybeNull(types.string),
      historyItems: types.optional(types.array(HistoryItem), [])
    })
  )
  .actions((self) => ({
    init(projectId: string): void {
      self.projectId = projectId;
      self.historyItems = cast([]);
    },
    insertHistoryItem(item: THistoryItemModel): void {
      self.historyItems = cast([...self.historyItems, item]);
    },
    getHistoryItemByIndex(index: number): THistoryItemModel | null {
      return self.historyItems[index] || null;
    },
    updateSaveDate(): void {
      self.lastSaveDate = new Date().toISOString();
    }
  }))
  .views((self) => ({
    get historyCount(): number {
      return self.historyItems.length;
    }
  }));

export type THistoryModel = Instance<typeof History>;

export type THistorySnapshotIn = SnapshotIn<typeof History>;

export {History};
