import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/rf-core';

const ElementInfo = types
  .model('ElementInfo', {
    id: types.string,
    type: types.enumeration(Object.values(EElement)),
    name: types.string
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TElementInfoModel = Instance<typeof ElementInfo>;

export type TElementInfoSnapshotIn = SnapshotIn<typeof ElementInfo>;

export {ElementInfo};
