import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {createBrowserHistory} from 'history';
import {theme} from '@progress-fe/ui-kit';

import {App} from 'scenes/App';
import {RootStore} from 'stores/RootStore';
import ErrorBoundary from 'core/components/ErrorBoundary/ErrorBoundary';

import {StoreProvider} from './core';

/**
 * init rootStore and pass dependencies
 * https://mobx-state-tree.js.org/concepts/dependency-injection
 */
const history = createBrowserHistory({});
const rootStore = RootStore.create({}, {history});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StoreProvider value={rootStore}>
    <ChakraProvider theme={theme} resetCSS>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </ChakraProvider>
  </StoreProvider>
);
