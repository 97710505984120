import {FC} from 'react';
import {Box, Text, Center} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EntityHeader} from '@progress-fe/ui-kit';
import {ElementIcon} from '@progress-fe/rf-core';
import {useT} from '@progress-fe/core';

import {EntityItem} from 'ui-kit';
import {TElementJsonSchemasModel} from 'core/models';

interface IProps {
  elements: TElementJsonSchemasModel[];
  selectedId: string | null;
  onSelect: (elementId: string) => void;
}

const ElementListFC: FC<IProps> = ({elements, selectedId, onSelect}) => {
  const {t} = useT();

  return (
    <Box overflowY="hidden">
      <EntityHeader title="Элементы" />
      <Box overflowY="auto" h="calc(100% - 40px)">
        {elements.map((el) => {
          return (
            <EntityItem
              key={el.id}
              name={el.namePropValue}
              suffix={t(`enum.element.${el.elementTypePropValue}`)}
              elementIcon={ElementIcon[el.type]}
              isActive={selectedId === el.id}
              onClick={() => onSelect(el.id)}
            />
          );
        })}

        {elements.length === 0 && (
          <Center pb="40px" height="100%">
            <Text>Нет элементов</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export const ElementList = observer(ElementListFC);
