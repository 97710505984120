import {Instance, types} from 'mobx-state-tree';

import {EProjectEntity} from 'core/enums';
import {ResetModel} from 'core/models';

const UiState = types
  .compose(
    ResetModel,
    types.model('UiState', {
      entityId: types.maybeNull(types.string),
      entityType: types.optional(
        types.enumeration(Object.values(EProjectEntity)),
        EProjectEntity.Settings
      )
    })
  )
  .actions((self) => ({
    select(entityType: EProjectEntity, entityId?: string | null): void {
      self.entityType = entityType;
      self.entityId = entityId || null;
    }
  }))
  .views(() => ({}));

export type TUiStateStore = Instance<typeof UiState>;

export {UiState};
