import {cast, Instance, types} from 'mobx-state-tree';

import {ElementJsonSchemas, ResetModel, TElementJsonSchemasModel} from 'core/models';
import {ELEMENTS_LIST} from 'core/mocks/elements.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      elements: types.optional(types.array(ElementJsonSchemas), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const elements = ELEMENTS_LIST.find((m) => m.projectId === projectId)?.items || [];
      self.elements = cast(elements);
    },
    hasElement(elementId: string): boolean {
      return self.elements.some((e) => e.id === elementId);
    },
    findElement(elementId: string): TElementJsonSchemasModel | undefined {
      return self.elements.find((e) => e.id === elementId);
    },
    resetCalculatedFieldsOfElements(): void {
      self.elements.forEach((element) => {
        element.jsonSchemas.forEach((jsonSchema) => {
          jsonSchema.resetFormFields();
        });
      });
    }
  }))
  .views(() => ({}));

export type TProjectElementsModel = Instance<typeof ProjectElements>;

export {ProjectElements};
