import {IconButton, Text, Grid, Box} from '@chakra-ui/react';
import {FC, Fragment, memo} from 'react';
import {useT} from '@progress-fe/core';

import {InputNumber} from '../../../../../../inputs';
import {TCompressorCurveValue} from '../../../../CompressorCurvesFormData.types';
import {Svg} from '../../../../../../helpers';

interface IProps {
  isReadonly: boolean;
  values: TCompressorCurveValue[];
  flowTitle: string;
  pressureTitle: string;
  onValuesChanged: (newValues: TCompressorCurveValue[]) => void;
  onDeleteItem: (indx: number) => void;
}

const CompressorCurveTableFC: FC<IProps> = ({
  isReadonly,
  values,
  flowTitle,
  pressureTitle,
  onDeleteItem,
  onValuesChanged
}) => {
  const {t} = useT();

  const handleItemChanged = (name: string, value: string | null, index: number) => {
    const newValues = values.map((item, idx) => (idx === index ? {...item, [name]: value} : item));
    onValuesChanged(newValues);
  };

  const isSingleValue = values.length === 1;

  return (
    <Grid
      templateColumns={isSingleValue ? 'repeat(3, 1fr)' : 'repeat(3, 1fr) 24px'}
      flexDirection="column"
      gap="4px"
      textAlign="center"
    >
      {/* Table header */}
      <Text flexGrow="1">{flowTitle || t('common.notSelected')}</Text>
      <Text flexGrow="1">{pressureTitle || t('common.notSelected')}</Text>
      <Text flexGrow="1">{t('jsfields.CompressorCurve.efficiency')}</Text>
      {isSingleValue ? <></> : <Box />}

      {/* Table body */}
      {values.map((item, index) => (
        <Fragment key={index}>
          {Object.keys(item).map((key) => (
            <InputNumber
              key={key}
              size="xs"
              variant="outline"
              value={item[key as keyof TCompressorCurveValue]}
              disabled={isReadonly}
              isOnChangeOnlyOnBlur
              isInvalid={!item[key as keyof TCompressorCurveValue]}
              onChange={(value) => handleItemChanged(key, value, index)}
            />
          ))}
          {!isSingleValue ? (
            <IconButton
              size="2sm"
              aria-label=""
              variant="ghost"
              flexShrink="0"
              isDisabled={isReadonly}
              icon={<Svg size="s12" name="Cross" />}
              onClick={() => onDeleteItem(index)}
            />
          ) : (
            <Box />
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export const CompressorCurveTable = memo(CompressorCurveTableFC);
