import {observer} from 'mobx-react-lite';
import {FC, useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import {JsFormName} from '@progress-fe/ui-kit';

import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';
import {TElementResultJsonSchemasModel, TJsonSchemaModel} from 'core/models';

interface IProps {
  resultInstance: TElementResultJsonSchemasModel;
}

const ElementResultFormsFC: FC<IProps> = ({resultInstance}) => {
  const {jsonSchemas, namePropValue} = resultInstance;

  const tabs: IJsonSchemaTab[] = useMemo(() => {
    return jsonSchemas.map((jsonSchema, index) => ({
      index: index,
      name: jsonSchema.name,
      jsonSchema
    }));
  }, [jsonSchemas]);

  const handleChangeFormData = (newFormData: unknown, jsonSchema: TJsonSchemaModel) => {
    jsonSchema.updateFormData(newFormData);
  };

  return (
    <Box>
      <JsFormName name={namePropValue} />
      <JsTabsFormWrapper
        tabs={tabs}
        onChange={(formData, tabId: number) => {
          handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
        }}
      />
    </Box>
  );
};

export const ElementResultForms = observer(ElementResultFormsFC);
