import {EElement} from '@progress-fe/rf-core';

import {TElementJsonSchemasSnapshotIn} from 'core/models';
/* SEPARATOR ELEMENTS */
import * as V_100_b from 'core/mocks/elements/Separator/2_phase_separator/V_100_basic';
import * as K_100_b from 'core/mocks/elements/Separator/compressor/K_100_basic';
import * as K_100_c from 'core/mocks/elements/Separator/compressor/K_100_characteristics';
import * as K_100_p from 'core/mocks/elements/Separator/compressor/K_100_performance';
import * as E_100_b from 'core/mocks/elements/Separator/cooler/E_100_basic';
import * as E_100_c from 'core/mocks/elements/Separator/cooler/E_100_characteristics';
import * as VLV_100_b from 'core/mocks/elements/Separator/valve/VLV_100_basic';
import * as V_101_b from 'core/mocks/elements/Separator/3_phase_separator/V_101_basic';
import * as Q_100_b from 'core/mocks/elements/Separator/energy_flows/Q_100_basic';
import * as Q_101_b from 'core/mocks/elements/Separator/energy_flows/Q_101_basic';
import * as MF_1_b from 'core/mocks/elements/Separator/material_flows/1/1_basic';
import * as MF_1_co from 'core/mocks/elements/Separator/material_flows/1/1_composition';
import * as MF_1_ch from 'core/mocks/elements/Separator/material_flows/1/1_characteristics';
import * as MF_1_p from 'core/mocks/elements/Separator/material_flows/1/1_props';
import * as MF_2_b from 'core/mocks/elements/Separator/material_flows/2/2_basic';
import * as MF_2_co from 'core/mocks/elements/Separator/material_flows/2/2_composition';
import * as MF_2_ch from 'core/mocks/elements/Separator/material_flows/2/2_characteristics';
import * as MF_2_p from 'core/mocks/elements/Separator/material_flows/2/2_props';
import * as MF_3_b from 'core/mocks/elements/Separator/material_flows/3/3_basic';
import * as MF_3_co from 'core/mocks/elements/Separator/material_flows/3/3_composition';
import * as MF_3_ch from 'core/mocks/elements/Separator/material_flows/3/3_characteristics';
import * as MF_3_p from 'core/mocks/elements/Separator/material_flows/3/3_props';
import * as MF_4_b from 'core/mocks/elements/Separator/material_flows/4/4_basic';
import * as MF_4_co from 'core/mocks/elements/Separator/material_flows/4/4_composition';
import * as MF_4_ch from 'core/mocks/elements/Separator/material_flows/4/4_characteristics';
import * as MF_4_p from 'core/mocks/elements/Separator/material_flows/4/4_props';
import * as MF_5_b from 'core/mocks/elements/Separator/material_flows/5/5_basic';
import * as MF_5_co from 'core/mocks/elements/Separator/material_flows/5/5_composition';
import * as MF_5_ch from 'core/mocks/elements/Separator/material_flows/5/5_characteristics';
import * as MF_5_p from 'core/mocks/elements/Separator/material_flows/5/5_props';
import * as MF_6_b from 'core/mocks/elements/Separator/material_flows/6/6_basic';
import * as MF_6_co from 'core/mocks/elements/Separator/material_flows/6/6_composition';
import * as MF_6_ch from 'core/mocks/elements/Separator/material_flows/6/6_characteristics';
import * as MF_6_p from 'core/mocks/elements/Separator/material_flows/6/6_props';
import * as MF_7_b from 'core/mocks/elements/Separator/material_flows/7/7_basic';
import * as MF_7_co from 'core/mocks/elements/Separator/material_flows/7/7_composition';
import * as MF_7_ch from 'core/mocks/elements/Separator/material_flows/7/7_characteristics';
import * as MF_7_p from 'core/mocks/elements/Separator/material_flows/7/7_props';
import * as MF_8_b from 'core/mocks/elements/Separator/material_flows/8/8_basic';
import * as MF_8_co from 'core/mocks/elements/Separator/material_flows/8/8_composition';
import * as MF_8_ch from 'core/mocks/elements/Separator/material_flows/8/8_characteristics';
import * as MF_8_p from 'core/mocks/elements/Separator/material_flows/8/8_props';
import * as MF_9_b from 'core/mocks/elements/Separator/material_flows/9/9_basic';
import * as MF_9_co from 'core/mocks/elements/Separator/material_flows/9/9_composition';
import * as MF_9_ch from 'core/mocks/elements/Separator/material_flows/9/9_characteristics';
import * as MF_9_p from 'core/mocks/elements/Separator/material_flows/9/9_props';
/* COKER ELEMENTS */
import * as COKER_100_b from 'core/mocks/elements/Coker/coker/COKER_100_basic';
import * as COKER_100_c from 'core/mocks/elements/Coker/coker/COKER_100_characteristics';
import * as COKER_100_p from 'core/mocks/elements/Coker/coker/COKER_100_products';
import * as COKER_MF_1_b from 'core/mocks/elements/Coker/material_flow/1_basic';
import * as COKER_MF_1_co from 'core/mocks/elements/Coker/material_flow/1_composition';
import * as COKER_MF_1_ch from 'core/mocks/elements/Coker/material_flow/1_characteristics';
import * as COKER_MF_1_p from 'core/mocks/elements/Coker/material_flow/1_props';

export const ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementJsonSchemasSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    items: [
      {
        id: '196C89E0-0000-0000-0000-000000000001',
        type: EElement.TwoPhaseSeparatorElement,
        name: 'V-100',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: V_100_b.UI_SCHEMA_2PHASE_SEPARATOR_BASIC,
            schema: V_100_b.SCHEMA_2PHASE_SEPARATOR_BASIC,
            formData: V_100_b.FORM_DATA_2PHASE_SEPARATOR_BASIC,
            resetFormData: V_100_b.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000002',
        type: EElement.CompressorElement,
        name: 'K-100',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000002',
            name: 'Базовые',
            uiSchema: K_100_b.UI_SCHEMA_COMPRESSOR,
            schema: K_100_b.SCHEMA_COMPRESSOR,
            formData: K_100_b.FORM_DATA_COMPRESSOR,
            resetFormData: K_100_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000003',
            name: 'Характеристики',
            uiSchema: K_100_c.UI_SCHEMA_COMPRESSOR_CHARACTERISTICS,
            schema: K_100_c.SCHEMA_COMPRESSOR_CHARACTERISTICS,
            formData: K_100_c.FORM_DATA_COMPRESSOR_CHARACTERISTICS,
            resetFormData: K_100_c.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000004',
            name: 'Производительность',
            uiSchema: K_100_p.UI_SCHEMA_COMPRESSOR_PERFORMANCE,
            schema: K_100_p.SCHEMA_COMPRESSOR_PERFORMANCE,
            formData: K_100_p.FORM_DATA_COMPRESSOR_PERFORMANCE,
            resetFormData: K_100_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000003',
        type: EElement.CoolerElement,
        name: 'E-100',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000005',
            name: 'Базовые',
            uiSchema: E_100_b.UI_SCHEMA_COOLER_BASIC,
            schema: E_100_b.SCHEMA_COOLER_BASIC,
            formData: E_100_b.FORM_DATA_COOLER_BASIC,
            resetFormData: E_100_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000006',
            name: 'Характеристики',
            uiSchema: E_100_c.UI_SCHEMA_COOLER_CHARACTERISTICS,
            schema: E_100_c.SCHEMA_COOLER_CHARACTERISTICS,
            formData: E_100_c.FORM_DATA_COOLER_CHARACTERISTICS,
            resetFormData: E_100_c.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000004',
        type: EElement.ValveElement,
        name: 'VLV-100',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000007',
            name: 'Базовые',
            uiSchema: VLV_100_b.UI_SCHEMA_VALVE,
            schema: VLV_100_b.SCHEMA_VALVE,
            formData: VLV_100_b.FORM_DATA_VALVE,
            resetFormData: VLV_100_b.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000005',
        type: EElement.ThreePhaseSeparatorElement,
        name: 'V-101',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000008',
            name: 'Базовые',
            uiSchema: V_101_b.UI_SCHEMA_3PHASE_SEPARATOR_BASIC,
            schema: V_101_b.SCHEMA_3PHASE_SEPARATOR_BASIC,
            formData: V_101_b.FORM_DATA_3PHASE_SEPARATOR_BASIC,
            resetFormData: V_101_b.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000006',
        type: EElement.EnergyFlowElement,
        name: 'Q-100',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000009',
            name: 'Базовые',
            uiSchema: Q_100_b.UI_SCHEMA_ENERGY_FLOW_BASIC,
            schema: Q_100_b.SCHEMA_ENERGY_FLOW_BASIC,
            formData: Q_100_b.FORM_DATA_ENERGY_FLOW_BASIC,
            resetFormData: Q_100_b.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000007',
        type: EElement.EnergyFlowElement,
        name: 'Q-101',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000010',
            name: 'Базовые',
            uiSchema: Q_101_b.UI_SCHEMA_ENERGY_FLOW_BASIC,
            schema: Q_101_b.SCHEMA_ENERGY_FLOW_BASIC,
            formData: Q_101_b.FORM_DATA_ENERGY_FLOW_BASIC,
            resetFormData: Q_101_b.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000008',
        type: EElement.MaterialFlowElement,
        name: '1',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000011',
            name: 'Базовые',
            uiSchema: MF_1_b.UI_SCHEMA_FLOW1_BASIC,
            schema: MF_1_b.SCHEMA_FLOW1_BASIC,
            formData: MF_1_b.FORM_DATA_FLOW1_BASIC,
            resetFormData: MF_1_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000012',
            name: 'Состав',
            uiSchema: MF_1_co.UI_SCHEMA_FLOW1_COMPOSITION,
            schema: MF_1_co.SCHEMA_FLOW1_COMPOSITION,
            formData: MF_1_co.FORM_DATA_FLOW1_COMPOSITION,
            resetFormData: MF_1_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000013',
            name: 'Характеристики',
            uiSchema: MF_1_ch.UI_SCHEMA_FLOW1_CHARACTERISTICS,
            schema: MF_1_ch.SCHEMA_FLOW1_CHARACTERISTICS,
            formData: MF_1_ch.FORM_DATA_FLOW1_CHARACTERISTICS,
            resetFormData: MF_1_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000014',
            name: 'Свойства',
            uiSchema: MF_1_p.UI_SCHEMA_FLOW1_PROPS,
            schema: MF_1_p.SCHEMA_FLOW1_PROPS,
            formData: MF_1_p.FORM_DATA_FLOW1_PROPS,
            resetFormData: MF_1_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000009',
        type: EElement.MaterialFlowElement,
        name: '2',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000015',
            name: 'Базовые',
            uiSchema: MF_2_b.UI_SCHEMA_FLOW2_BASIC,
            schema: MF_2_b.SCHEMA_FLOW2_BASIC,
            formData: MF_2_b.FORM_DATA_FLOW2_BASIC,
            resetFormData: MF_2_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000016',
            name: 'Состав',
            uiSchema: MF_2_co.UI_SCHEMA_FLOW2_COMPOSITION,
            schema: MF_2_co.SCHEMA_FLOW2_COMPOSITION,
            formData: MF_2_co.FORM_DATA_FLOW2_COMPOSITION,
            resetFormData: MF_2_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000017',
            name: 'Характеристики',
            uiSchema: MF_2_ch.UI_SCHEMA_FLOW2_CHARACTERISTICS,
            schema: MF_2_ch.SCHEMA_FLOW2_CHARACTERISTICS,
            formData: MF_2_ch.FORM_DATA_FLOW2_CHARACTERISTICS,
            resetFormData: MF_2_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000018',
            name: 'Свойства',
            uiSchema: MF_2_p.UI_SCHEMA_FLOW2_PROPS,
            schema: MF_2_p.SCHEMA_FLOW2_PROPS,
            formData: MF_2_p.FORM_DATA_FLOW2_PROPS,
            resetFormData: MF_2_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000010',
        type: EElement.MaterialFlowElement,
        name: '3',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000019',
            name: 'Базовые',
            uiSchema: MF_3_b.UI_SCHEMA_FLOW3_BASIC,
            schema: MF_3_b.SCHEMA_FLOW3_BASIC,
            formData: MF_3_b.FORM_DATA_FLOW3_BASIC,
            resetFormData: MF_3_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000020',
            name: 'Состав',
            uiSchema: MF_3_co.UI_SCHEMA_FLOW3_COMPOSITION,
            schema: MF_3_co.SCHEMA_FLOW3_COMPOSITION,
            formData: MF_3_co.FORM_DATA_FLOW3_COMPOSITION,
            resetFormData: MF_3_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000021',
            name: 'Характеристики',
            uiSchema: MF_3_ch.UI_SCHEMA_FLOW3_CHARACTERISTICS,
            schema: MF_3_ch.SCHEMA_FLOW3_CHARACTERISTICS,
            formData: MF_3_ch.FORM_DATA_FLOW3_CHARACTERISTICS,
            resetFormData: MF_3_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000022',
            name: 'Свойства',
            uiSchema: MF_3_p.UI_SCHEMA_FLOW3_PROPS,
            schema: MF_3_p.SCHEMA_FLOW3_PROPS,
            formData: MF_3_p.FORM_DATA_FLOW3_PROPS,
            resetFormData: MF_3_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000011',
        type: EElement.MaterialFlowElement,
        name: '4',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000023',
            name: 'Базовые',
            uiSchema: MF_4_b.UI_SCHEMA_FLOW4_BASIC,
            schema: MF_4_b.SCHEMA_FLOW4_BASIC,
            formData: MF_4_b.FORM_DATA_FLOW4_BASIC,
            resetFormData: MF_4_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000024',
            name: 'Состав',
            uiSchema: MF_4_co.UI_SCHEMA_FLOW4_COMPOSITION,
            schema: MF_4_co.SCHEMA_FLOW4_COMPOSITION,
            formData: MF_4_co.FORM_DATA_FLOW4_COMPOSITION,
            resetFormData: MF_4_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000025',
            name: 'Характеристики',
            uiSchema: MF_4_ch.UI_SCHEMA_FLOW4_CHARACTERISTICS,
            schema: MF_4_ch.SCHEMA_FLOW4_CHARACTERISTICS,
            formData: MF_4_ch.FORM_DATA_FLOW4_CHARACTERISTICS,
            resetFormData: MF_4_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000026',
            name: 'Свойства',
            uiSchema: MF_4_p.UI_SCHEMA_FLOW4_PROPS,
            schema: MF_4_p.SCHEMA_FLOW4_PROPS,
            formData: MF_4_p.FORM_DATA_FLOW4_PROPS,
            resetFormData: MF_4_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000012',
        type: EElement.MaterialFlowElement,
        name: '5',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000027',
            name: 'Базовые',
            uiSchema: MF_5_b.UI_SCHEMA_FLOW5_BASIC,
            schema: MF_5_b.SCHEMA_FLOW5_BASIC,
            formData: MF_5_b.FORM_DATA_FLOW5_BASIC,
            resetFormData: MF_5_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000028',
            name: 'Состав',
            uiSchema: MF_5_co.UI_SCHEMA_FLOW5_COMPOSITION,
            schema: MF_5_co.SCHEMA_FLOW5_COMPOSITION,
            formData: MF_5_co.FORM_DATA_FLOW5_COMPOSITION,
            resetFormData: MF_5_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000029',
            name: 'Характеристики',
            uiSchema: MF_5_ch.UI_SCHEMA_FLOW5_CHARACTERISTICS,
            schema: MF_5_ch.SCHEMA_FLOW5_CHARACTERISTICS,
            formData: MF_5_ch.FORM_DATA_FLOW5_CHARACTERISTICS,
            resetFormData: MF_5_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000030',
            name: 'Свойства',
            uiSchema: MF_5_p.UI_SCHEMA_FLOW5_PROPS,
            schema: MF_5_p.SCHEMA_FLOW5_PROPS,
            formData: MF_5_p.FORM_DATA_FLOW5_PROPS,
            resetFormData: MF_5_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000013',
        type: EElement.MaterialFlowElement,
        name: '6',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000031',
            name: 'Базовые',
            uiSchema: MF_6_b.UI_SCHEMA_FLOW6_BASIC,
            schema: MF_6_b.SCHEMA_FLOW6_BASIC,
            formData: MF_6_b.FORM_DATA_FLOW6_BASIC,
            resetFormData: MF_6_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000032',
            name: 'Состав',
            uiSchema: MF_6_co.UI_SCHEMA_FLOW6_COMPOSITION,
            schema: MF_6_co.SCHEMA_FLOW6_COMPOSITION,
            formData: MF_6_co.FORM_DATA_FLOW6_COMPOSITION,
            resetFormData: MF_6_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000033',
            name: 'Характеристики',
            uiSchema: MF_6_ch.UI_SCHEMA_FLOW6_CHARACTERISTICS,
            schema: MF_6_ch.SCHEMA_FLOW6_CHARACTERISTICS,
            formData: MF_6_ch.FORM_DATA_FLOW6_CHARACTERISTICS,
            resetFormData: MF_6_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000034',
            name: 'Свойства',
            uiSchema: MF_6_p.UI_SCHEMA_FLOW6_PROPS,
            schema: MF_6_p.SCHEMA_FLOW6_PROPS,
            formData: MF_6_p.FORM_DATA_FLOW6_PROPS,
            resetFormData: MF_6_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000014',
        type: EElement.MaterialFlowElement,
        name: '7',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000035',
            name: 'Базовые',
            uiSchema: MF_7_b.UI_SCHEMA_FLOW7_BASIC,
            schema: MF_7_b.SCHEMA_FLOW7_BASIC,
            formData: MF_7_b.FORM_DATA_FLOW7_BASIC,
            resetFormData: MF_7_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000036',
            name: 'Состав',
            uiSchema: MF_7_co.UI_SCHEMA_FLOW7_COMPOSITION,
            schema: MF_7_co.SCHEMA_FLOW7_COMPOSITION,
            formData: MF_7_co.FORM_DATA_FLOW7_COMPOSITION,
            resetFormData: MF_7_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000037',
            name: 'Характеристики',
            uiSchema: MF_7_ch.UI_SCHEMA_FLOW7_CHARACTERISTICS,
            schema: MF_7_ch.SCHEMA_FLOW7_CHARACTERISTICS,
            formData: MF_7_ch.FORM_DATA_FLOW7_CHARACTERISTICS,
            resetFormData: MF_7_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000038',
            name: 'Свойства',
            uiSchema: MF_7_p.UI_SCHEMA_FLOW7_PROPS,
            schema: MF_7_p.SCHEMA_FLOW7_PROPS,
            formData: MF_7_p.FORM_DATA_FLOW7_PROPS,
            resetFormData: MF_7_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000015',
        type: EElement.MaterialFlowElement,
        name: '8',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000039',
            name: 'Базовые',
            uiSchema: MF_8_b.UI_SCHEMA_FLOW8_BASIC,
            schema: MF_8_b.SCHEMA_FLOW8_BASIC,
            formData: MF_8_b.FORM_DATA_FLOW8_BASIC,
            resetFormData: MF_8_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000040',
            name: 'Состав',
            uiSchema: MF_8_co.UI_SCHEMA_FLOW8_COMPOSITION,
            schema: MF_8_co.SCHEMA_FLOW8_COMPOSITION,
            formData: MF_8_co.FORM_DATA_FLOW8_COMPOSITION,
            resetFormData: MF_8_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000041',
            name: 'Характеристики',
            uiSchema: MF_8_ch.UI_SCHEMA_FLOW8_CHARACTERISTICS,
            schema: MF_8_ch.SCHEMA_FLOW8_CHARACTERISTICS,
            formData: MF_8_ch.FORM_DATA_FLOW8_CHARACTERISTICS,
            resetFormData: MF_8_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000042',
            name: 'Свойства',
            uiSchema: MF_8_p.UI_SCHEMA_FLOW8_PROPS,
            schema: MF_8_p.SCHEMA_FLOW8_PROPS,
            formData: MF_8_p.FORM_DATA_FLOW8_PROPS,
            resetFormData: MF_8_p.REVERT_FORM_DATA
          }
        ]
      },
      {
        id: '196C89E0-0000-0000-0000-000000000016',
        type: EElement.MaterialFlowElement,
        name: '9',
        jsonSchemas: [
          {
            id: '43EABD3F-0000-0000-0000-000000000043',
            name: 'Базовые',
            uiSchema: MF_9_b.UI_SCHEMA_FLOW9_BASIC,
            schema: MF_9_b.SCHEMA_FLOW9_BASIC,
            formData: MF_9_b.FORM_DATA_FLOW9_BASIC,
            resetFormData: MF_9_b.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000044',
            name: 'Состав',
            uiSchema: MF_9_co.UI_SCHEMA_FLOW9_COMPOSITION,
            schema: MF_9_co.SCHEMA_FLOW9_COMPOSITION,
            formData: MF_9_co.FORM_DATA_FLOW9_COMPOSITION,
            resetFormData: MF_9_co.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000045',
            name: 'Характеристики',
            uiSchema: MF_9_ch.UI_SCHEMA_FLOW9_CHARACTERISTICS,
            schema: MF_9_ch.SCHEMA_FLOW9_CHARACTERISTICS,
            formData: MF_9_ch.FORM_DATA_FLOW9_CHARACTERISTICS,
            resetFormData: MF_9_ch.REVERT_FORM_DATA
          },
          {
            id: '43EABD3F-0000-0000-0000-000000000046',
            name: 'Свойства',
            uiSchema: MF_9_p.UI_SCHEMA_FLOW9_PROPS,
            schema: MF_9_p.SCHEMA_FLOW9_PROPS,
            formData: MF_9_p.FORM_DATA_FLOW9_PROPS,
            resetFormData: MF_9_p.REVERT_FORM_DATA
          }
        ]
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    items: [
      {
        id: '704F784D-0000-0000-0000-000000000001',
        type: EElement.CatCrackingElement,
        name: 'COKER-100',
        jsonSchemas: [
          {
            id: 'FA707384-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: COKER_100_b.UI_SCHEMA_COKER,
            schema: COKER_100_b.SCHEMA_COKER,
            formData: COKER_100_b.FORM_DATA_COKER,
            resetFormData: COKER_100_b.REVERT_FORM_DATA
          },
          {
            id: 'FA707384-0000-0000-0000-000000000002',
            name: 'Характеристики',
            uiSchema: COKER_100_c.UI_SCHEMA_COKER_CHARACTERISTICS,
            schema: COKER_100_c.SCHEMA_COKER_CHARACTERISTICS,
            formData: COKER_100_c.FORM_DATA_COKER_CHARACTERISTICS,
            resetFormData: COKER_100_c.REVERT_FORM_DATA
          },
          {
            id: 'FA707384-0000-0000-0000-000000000003',
            name: 'Продукты',
            uiSchema: COKER_100_p.UI_SCHEMA_COKER_PRODUCTS,
            schema: COKER_100_p.SCHEMA_COKER_PRODUCTS,
            formData: COKER_100_p.FORM_DATA_COKER_PRODUCTS,
            resetFormData: COKER_100_p.REVERT_FORM_DATA,
            preventRevertFields: COKER_100_p.PREVENT_REVERT_FIELDS
          }
        ]
      },
      {
        id: '704F784D-0000-0000-0000-000000000002',
        type: EElement.MaterialFlowElement,
        name: '1',
        jsonSchemas: [
          {
            id: 'FA707384-0000-0000-0000-000000000004',
            name: 'Базовые',
            uiSchema: COKER_MF_1_b.UI_SCHEMA_FLOW1_BASIC,
            schema: COKER_MF_1_b.SCHEMA_FLOW1_BASIC,
            formData: COKER_MF_1_b.FORM_DATA_FLOW1_BASIC,
            resetFormData: COKER_MF_1_b.REVERT_FORM_DATA
          },
          {
            id: 'FA707384-0000-0000-0000-000000000005',
            name: 'Состав',
            uiSchema: COKER_MF_1_co.UI_SCHEMA_FLOW1_COMPOSITION,
            schema: COKER_MF_1_co.SCHEMA_FLOW1_COMPOSITION,
            formData: COKER_MF_1_co.FORM_DATA_FLOW1_COMPOSITION,
            resetFormData: COKER_MF_1_co.REVERT_FORM_DATA
          },
          {
            id: 'FA707384-0000-0000-0000-000000000006',
            name: 'Характеристики',
            uiSchema: COKER_MF_1_ch.UI_SCHEMA_FLOW1_CHARACTERISTICS,
            schema: COKER_MF_1_ch.SCHEMA_FLOW1_CHARACTERISTICS,
            formData: COKER_MF_1_ch.FORM_DATA_FLOW1_CHARACTERISTICS,
            resetFormData: COKER_MF_1_ch.REVERT_FORM_DATA
          },
          {
            id: 'FA707384-0000-0000-0000-000000000007',
            name: 'Свойства',
            uiSchema: COKER_MF_1_p.UI_SCHEMA_FLOW1_PROPS,
            schema: COKER_MF_1_p.SCHEMA_FLOW1_PROPS,
            formData: COKER_MF_1_p.FORM_DATA_FLOW1_PROPS,
            resetFormData: COKER_MF_1_p.REVERT_FORM_DATA
          }
        ]
      }
    ]
  }
];
