import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {TProjectEntityJsonSchemasModel} from 'core/models';
import {EProjectEntity} from 'core/enums';
import {ProjectEntitiesList} from 'ui-kit';

// TODO: unknown should be enum of available types
interface IProps {
  probes: TProjectEntityJsonSchemasModel[];
  blends: TProjectEntityJsonSchemasModel[];
  selectedId: string | null;
  onSelectEntity: (entityId: string, type: EProjectEntity) => void;
  onCreateModel: (type: EProjectEntity) => void;
}

const SamplesAndOilStructureFC: FC<IProps> = ({
  probes,
  blends,
  selectedId,
  onSelectEntity,
  onCreateModel
}) => {
  const {t} = useT();

  return (
    <Grid gridTemplateRows={'1fr 8px 1fr'} h="var(--height-layout)">
      <ProjectEntitiesList
        title={t('common.probes')}
        entities={probes}
        noEntitiesText={t('common.noProbes')}
        icon="Sample"
        dropDownMenu={[{id: '1', name: 'test', items: [{id: '1', name: 'test'}]}]}
        selectedId={selectedId}
        onCreateModel={() => onCreateModel(EProjectEntity.Probe)}
        onSelect={(id) => onSelectEntity(id, EProjectEntity.Probe)}
      />

      <FlexDivider withDots />

      <ProjectEntitiesList
        title={t('common.blends')}
        entities={blends}
        noEntitiesText={t('common.noBlends')}
        icon="Blend"
        selectedId={selectedId}
        dropDownMenu={[{id: '1', name: 'test', items: [{id: '1', name: 'test'}]}]}
        onCreateModel={() => onCreateModel(EProjectEntity.Blend)}
        onSelect={(id) => onSelectEntity(id, EProjectEntity.Blend)}
      />
    </Grid>
  );
};

export const SamplesAndOilStructure = observer(SamplesAndOilStructureFC);
