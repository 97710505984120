import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EModuleType} from 'core/enums';
import {ProjectTypeEnum} from 'api';

const ProjectInfo = types
  .model('ProjectInfo', {
    id: types.identifier,
    name: types.string,
    author: types.string,
    modified: types.string,
    moduleType: types.enumeration(Object.values(EModuleType)),
    type: types.enumeration(Object.values(ProjectTypeEnum))
  })

  .actions(() => ({}))
  .views(() => ({}));

export type TProjectInfoModel = Instance<typeof ProjectInfo>;

export type TProjectInfoSnapshotIn = SnapshotIn<typeof ProjectInfo>;

export {ProjectInfo};
