import {cast, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';
import {cloneDeep} from 'lodash-es';
import {hasOwnPropertyByPath} from '@progress-fe/core';

import {EModelType} from 'core/enums';
import {ElementModelJsonSchemas, ResetModel} from 'core/models';
import {MODELS_LIST} from 'core/mocks/models.mocks';

const ProjectElementModels = types
  .compose(
    ResetModel,
    types.model('ProjectModels', {
      models: types.optional(types.array(ElementModelJsonSchemas), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const models = MODELS_LIST.find((m) => m.projectId === projectId)?.items || [];
      self.models = cast(models);
    },
    hasModel(modelId: string) {
      return self.models.some((m) => m.id === modelId);
    },
    createModel(type: EModelType): void {
      const robinson2 = JSON.parse(JSON.stringify(MODELS_LIST[0].items[1]));
      const robinsonExisingCount = self.models.filter((m) => m.type === type).length;

      // @ts-ignore
      robinson2.jsonSchemas[0].formData.basic.name = `Пенг-Робинсон-${robinsonExisingCount + 1}`;
      robinson2.id = uuidv4();

      self.models = cast([robinson2, ...self.models]);
    },
    changeDefaultModel(modelType: EModelType, modelId: string): void {
      self.models.forEach((model) => {
        if (model.type === modelType) {
          model.jsonSchemas.forEach((jsonSchema) => {
            if (hasOwnPropertyByPath(jsonSchema.formData as object, 'basic.isDefaultModel')) {
              // @ts-ignore
              const isDefaultModelValue = jsonSchema.formData.basic.isDefaultModel;
              if (isDefaultModelValue && modelId !== model.id) {
                const formDataClone = cloneDeep(jsonSchema.formData);

                // @ts-ignore
                formDataClone.basic.isDefaultModel = false;
                jsonSchema.formData = formDataClone;
              }
            }
          });
        }
      });
    },
    getModelNameById(modelId: string) {
      const model = self.models.find((m) => m.id === modelId);
      return model?.namePropValue || null;
    }
  }))
  .views(() => ({}));

export type TProjectModelsModel = Instance<typeof ProjectElementModels>;

export {ProjectElementModels};
