import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EModelType} from 'core/enums';

const ElementModelInfo = types
  .model('ModelInfo', {
    id: types.string,
    name: types.string,
    type: types.enumeration(Object.values(EModelType)),
    icon: types.string
  })

  .actions(() => ({}))
  .views(() => ({}));

export type TElementModelInfoModel = Instance<typeof ElementModelInfo>;

export type TElementModelInfoSnapshotIn = SnapshotIn<typeof ElementModelInfo>;

export {ElementModelInfo};
