import {DragEvent, FC, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {EventEmitter} from '@progress-fe/core';
import {
  Edge,
  Node,
  Connection,
  addEdge,
  useStoreApi,
  useEdgesState,
  useNodesState,
  useOnSelectionChange,
  ReactFlowInstance
} from '@xyflow/react';
import {
  RFRender,
  isEnergyPort,
  portsHaveSameFlowType,
  IRFMenuItem,
  TRFEdgeDataConfig,
  TRFStructDataConfig,
  RF_FIT_VIEW_MAX_ZOOM,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS,
  RF_DRAG_NODE_TYPE,
  RF_DRAG_TEMPLATE_CODE
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';
import {EProjectEntity} from 'core/enums';

interface IProps {
  menuItems: IRFMenuItem[];
  initialNodes: Node<TRFStructDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  selectedEntityId: string | null;
  selectedEntityType: EProjectEntity;
  height: number;
  width: number;
}

const RFStructureFC: FC<IProps> = ({
  initialNodes,
  initialEdges,
  selectedEntityId,
  selectedEntityType,
  menuItems,
  height,
  width
}) => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const pickedEntityId = useRef<string | null>(null);

  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFStructDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const {getState} = useStoreApi();
  const {addSelectedNodes, addSelectedEdges, resetSelectedElements} = getState();

  // Init initial edges & nodes
  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialEdges, initialNodes, setEdges, setNodes]);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  // Pick specific node or edge
  useEffect(() => {
    const pickEntity = (entityId: string) => {
      pickedEntityId.current = entityId;

      const foundNode = nodes.find((n) => n.id === entityId);
      const foundEdge = edges.find((n) => n.id === entityId);
      if (foundNode) addSelectedNodes([foundNode.id]);
      if (foundEdge) addSelectedEdges([foundEdge.id]);
    };

    EventEmitter.on('PickEntity', pickEntity);

    return () => {
      EventEmitter.off('PickEntity', pickEntity);
    };
  }, [addSelectedEdges, addSelectedNodes, edges, nodes]);

  // Select specific node or edge
  useEffect(() => {
    if (selectedEntityId && selectedEntityType === EProjectEntity.Element) {
      const foundNode = nodes.find((n) => n.id === selectedEntityId);
      const foundEdge = edges.find((n) => n.id === selectedEntityId);
      if (foundNode) addSelectedNodes([foundNode.id]);
      if (foundEdge) addSelectedEdges([foundEdge.id]);
    } else {
      resetSelectedElements();
    }
    // FYI: Should be only 1 dep
    // eslint-disable-next-line
  }, [selectedEntityId]);

  // Specific node or edge was selected
  useOnSelectionChange({
    onChange: (params) => {
      const selectedNodes: Node[] = params.nodes;
      const selectedEdges: Edge[] = params.edges;

      let newSelectedEntityId: string | null | undefined = null;
      if (selectedNodes.length > 0) newSelectedEntityId = selectedNodes[0].id;
      if (selectedEdges.length > 0) newSelectedEntityId = selectedEdges[0].id;

      // Don't emit event when node was selected by the Pick button
      if (newSelectedEntityId && newSelectedEntityId !== pickedEntityId.current) {
        EventEmitter.emit('SelectEntity', newSelectedEntityId);
      }

      // Just clear node which was selected by the Pick button
      if (newSelectedEntityId && newSelectedEntityId === pickedEntityId.current) {
        pickedEntityId.current = null;
      }
    }
  });

  const onConnect = useCallback(
    (connection: Connection) => {
      setEdges((existingEdges) => {
        const isValid = !!connection.source && !!connection.target;
        const hasHandles = !!connection.sourceHandle && !!connection.targetHandle;
        const isLogicValid = portsHaveSameFlowType(
          connection.sourceHandle,
          connection.targetHandle
        );

        if (!isValid || !hasHandles || !isLogicValid) return existingEdges;
        const isEdgeEnergy = isEnergyPort(connection.sourceHandle);

        const edge: Edge<TRFEdgeDataConfig> = {
          id: uuidv4(),
          ...connection,
          type: isEdgeEnergy ? 'energy' : 'material',
          data: {elementId: uuidv4(), defaultName: existingEdges.length + 1},
          ...(isEdgeEnergy ? RF_ENERGY_EDGE_PROPS : RF_MATERIAL_EDGE_PROPS)
        };

        return addEdge(edge, existingEdges);
      });
    },
    [setEdges]
  );

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const type = event.dataTransfer.getData(RF_DRAG_NODE_TYPE);
      const templateCode = event.dataTransfer.getData(RF_DRAG_TEMPLATE_CODE);

      const position = instance?.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });

      // check if the dropped element is valid
      if (!position || !type) return;

      // TODO
      const newNode: Node<TRFStructDataConfig> = {
        id: uuidv4(),
        type,
        position,
        data: {elementName: type, templateCode: templateCode} // TODO: From be side
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [instance, setNodes]
  );

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <RFMenu menuItems={menuItems} height={height} />
      <Box width="100%" height={height} ref={reactFlowWrapper}>
        <RFRender
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onDrop={onDrop}
        />
      </Box>
    </Grid>
  );
};

export const RFStructure = observer(RFStructureFC);
