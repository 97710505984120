import {FC, memo} from 'react';
import {Box, Grid, IconButton} from '@chakra-ui/react';
import {hasValue} from '@progress-fe/core';

import {Svg} from '../../../../helpers';
import {Select} from '../../../../inputs';
import {ISelectOption} from '../../../../../interfaces';

interface IProps {
  name: string;
  value: string | undefined;
  isDisabled: boolean | undefined;
  options: ISelectOption<string>[];
  onChange: (value: ISelectOption<string>) => void;
  onSelect: (id: string) => void;
  onPick: (id: string) => void;
}

const SelectNodeFC: FC<IProps> = ({
  name,
  value,
  isDisabled,
  options,
  onChange,
  onSelect,
  onPick
}) => {
  return (
    <Grid gridTemplateColumns="1fr 24px 24px" gap="4px">
      <Select
        name={name}
        options={options}
        isDisabled={isDisabled}
        placeholder="Не выбрано"
        value={options.find((i) => i.value === value)}
        onChange={(newValue) => onChange(newValue as ISelectOption<string>)}
      />
      <IconButton
        size="2sm"
        aria-label=""
        variant="ghost"
        isDisabled={isDisabled || !hasValue(value)}
        icon={<Svg size="s12" name="Pick" />}
        onClick={() => {
          if (hasValue(value)) {
            onPick(value);
          }
        }}
      />
      <IconButton
        size="2sm"
        aria-label=""
        variant="ghost"
        isDisabled={isDisabled || !hasValue(value)}
        icon={
          <Box transform="rotate(180deg)">
            <Svg size="s12" name="Back" />
          </Box>
        }
        onClick={() => {
          if (hasValue(value)) {
            onSelect(value);
          }
        }}
      />
    </Grid>
  );
};

export const SelectNode = memo(SelectNodeFC);
