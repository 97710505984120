import {observer} from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router';
import {useEffect} from 'react';
import {Grid, GridItem} from '@chakra-ui/react';

import {useStore} from 'core/hooks';
import {ROUTES} from 'core/constants';
import {ProjectLayout} from 'ui-kit';
import {EProjectEntity} from 'core/enums';

import {ProjectForms, ProjectStructure, ProjectDrawing} from './components';

const TechProcessSceneFC = () => {
  const {myProjectsStore, techProcessStore, catalogsStore} = useStore();
  const {uiState, projectModels, projectElements, projectResults, projectDrawing, isNotFound} =
    techProcessStore;

  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      return;
    }

    techProcessStore.initProject(id);

    return () => {
      techProcessStore.uninitialized();
    };
  }, [id, myProjectsStore, navigate, techProcessStore]);

  useEffect(() => {
    if (!isNotFound && id) {
      return;
    }
    navigate(ROUTES.MyProjects, {replace: true});
  }, [isNotFound, navigate, id]);

  return (
    <ProjectLayout project={techProcessStore}>
      <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'280px 440px 1fr'}>
        {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <ProjectStructure
            models={projectModels.models}
            elements={projectElements.elements}
            results={projectResults.elementsSchemasResults}
            selectedId={uiState.entityId}
            selectedType={uiState.entityType}
            onSelectEntity={uiState.select}
            onCreateModel={projectModels.createModel}
          />
        </GridItem>

        {/* SELECTED PROJECT ITEM */}
        <GridItem
          bg="bg"
          borderRight="1px"
          borderColor="border"
          overflowY={uiState.entityType === EProjectEntity.Settings ? 'hidden' : 'auto'}
        >
          <ProjectForms
            selectedType={uiState.entityType}
            selectedModelInstance={techProcessStore.selectedModel || undefined}
            selectedElementInstance={techProcessStore.selectedElement || undefined}
            selectedResultInstance={techProcessStore.selectedResultSchema || undefined}
            taskInstance={techProcessStore.projectTask}
            onChangeDefaultModel={projectModels.changeDefaultModel}
            onResetCalculatedFields={
              techProcessStore.isResettingFieldsNeeded
                ? techProcessStore.resetCalculatedFields
                : undefined
            }
          />
        </GridItem>

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem>
          <ProjectDrawing
            selectedEntityId={uiState.entityId}
            selectedEntityType={uiState.entityType}
            structureNodes={projectDrawing.structureDrawing.nodes}
            structureEdges={projectDrawing.structureDrawing.edges}
            structureMenuItems={catalogsStore.elementsCatalog.menuItems}
            calculationNodes={projectDrawing.calculationDrawing.nodes}
            calculationEdges={projectDrawing.calculationDrawing.edges}
            resultsInstances={projectResults.elementsSchemasResults}
            onSelectEntity={(entityType) => {
              const entityId =
                entityType === EProjectEntity.Result
                  ? projectResults.elementsSchemasResults[0]?.id
                  : undefined;

              uiState.select(entityType, entityId);
            }}
          />
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const TechProcessScene = observer(TechProcessSceneFC);
export default TechProcessScene;
