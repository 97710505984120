import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema, ElementModelInfo} from 'core/models';

const ElementModelJsonSchemas = types
  .compose(
    ElementModelInfo,
    types.model('ElementModelJsonSchemas', {
      jsonSchemas: types.optional(types.array(JsonSchema), [])
    })
  )
  .actions(() => ({}))
  .views((self) => ({
    get namePropValue(): string {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.name || ''
        : '';
    }
  }));

export type TElementModelJsonSchemasModel = Instance<typeof ElementModelJsonSchemas>;

export type TElementModelJsonSchemasSnapshotIn = SnapshotIn<typeof ElementModelJsonSchemas>;

export {ElementModelJsonSchemas};
