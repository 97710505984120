/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  TechProcessAction,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    TechProcessActionFromJSON,
    TechProcessActionToJSON,
} from '../models/index';

export interface TechProcessApplyActionsRequest {
    projectUuid: string;
    checkpointUuid: string;
    techProcessAction: Array<TechProcessAction>;
}

/**
 * 
 */
export class TechProcessApi extends runtime.BaseAPI {

    /**
     * Apply Actions
     */
    async techProcessApplyActionsRaw(requestParameters: TechProcessApplyActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['projectUuid'] == null) {
            throw new runtime.RequiredError(
                'projectUuid',
                'Required parameter "projectUuid" was null or undefined when calling techProcessApplyActions().'
            );
        }

        if (requestParameters['checkpointUuid'] == null) {
            throw new runtime.RequiredError(
                'checkpointUuid',
                'Required parameter "checkpointUuid" was null or undefined when calling techProcessApplyActions().'
            );
        }

        if (requestParameters['techProcessAction'] == null) {
            throw new runtime.RequiredError(
                'techProcessAction',
                'Required parameter "techProcessAction" was null or undefined when calling techProcessApplyActions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/actions`.replace(`{${"project_uuid"}}`, encodeURIComponent(String(requestParameters['projectUuid']))).replace(`{${"checkpoint_uuid"}}`, encodeURIComponent(String(requestParameters['checkpointUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['techProcessAction']!.map(TechProcessActionToJSON),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Apply Actions
     */
    async techProcessApplyActions(requestParameters: TechProcessApplyActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.techProcessApplyActionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
