import {TProjectInfoSnapshotIn} from 'core/models';
import {EModuleType} from 'core/enums';
import {ProjectTypeEnum} from 'api';

export const PROJECT_LIST: TProjectInfoSnapshotIn[] = [
  {
    id: '47CB2F0C-0000-0000-0000-000000000001',
    name: 'Схема с сепараторами',
    author: 'Иван Пропанов',
    modified: '2023-12-04T12:06:39.055Z',
    moduleType: EModuleType.SEPARATOR,
    type: ProjectTypeEnum.TechProcess
  },
  {
    id: '47CB2F0C-0000-0000-0000-000000000002',
    name: 'Схема с УЗК',
    author: 'Иван Метанов',
    modified: '2023-12-03T12:06:39.055Z',
    moduleType: EModuleType.COKER,
    type: ProjectTypeEnum.TechProcess
  }
];
