import {FC, ReactElement, ReactNode, useCallback, useState} from 'react';
import {Box, Flex, IconButton, VStack} from '@chakra-ui/react';

import {Dialog, Svg} from '../../helpers';

import {ComponentPlacement} from './components';

type TRenderChildProp = (isDialog: boolean, onToggleDialog: () => void) => ReactElement;

const isChildrenRenderProps = (val: unknown): val is TRenderChildProp => {
  return typeof val === 'function';
};

interface IProps {
  title: string;
  children: ReactNode | TRenderChildProp;
  isReadOnly?: boolean;
  dialogMinWidth?:string;
  floatMinWidth?:string;
}

export const DialogableContainer: FC<IProps> = ({title, children, isReadOnly, dialogMinWidth, floatMinWidth}) => {
  const [isDialog, setIsDialog] = useState(false);

  const handleToggleDialog = useCallback(() => {
    setIsDialog((prev) => !prev);
  }, []);

  const content = isChildrenRenderProps(children)
    ? children(isDialog, handleToggleDialog)
    : children;

  return (
    <>
      {!isDialog ? (
        <VStack gap="8px">
          {content}
          {!isChildrenRenderProps(children) &&<Flex gap="4px" justify="space-between" w="100%">
            <Box flexGrow={1} />
            <IconButton
              flexShrink={0}
              size="2sm"
              aria-label=""
              variant="ghost"
              icon={<Svg size="s12" name="Expand" />}
              isDisabled={isReadOnly}
              onClick={handleToggleDialog}
            />
          </Flex>}
        </VStack>
      ) : (
        <ComponentPlacement onClick={handleToggleDialog} />
      )}

      {isDialog && (
        <Dialog title={title} onClose={handleToggleDialog} isFloating minWidth={dialogMinWidth} floatMinWidth={floatMinWidth}>
          {content}
        </Dialog>
      )}
    </>
  );
};
