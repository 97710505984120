import {TableVirtuoso} from 'react-virtuoso';
import {Grid, Text, Flex, Center} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';

import {Checkbox} from '../../inputs';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../interfaces';

export enum TABLE_VARIANTS {
  FIRST_COLOMN_FIXED
}

interface IProps<T> {
  isCheckboxShown?: boolean;
  noItemsTitle?: string;
  columns: ISimpleTableColumn[];
  rows: ISimpleTableRow<T>[];
  onSelect?: (value: T, isSelected: boolean) => void;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  tableSx?: SystemStyleObject;
  variant?: TABLE_VARIANTS;
}

const VirtualTableFC = <T,>({
  isCheckboxShown,
  noItemsTitle,
  rows,
  columns,
  onSelect,
  onSelectAll,
  onUnselectAll,
  tableSx = {},
  variant
}: IProps<T>) => {
  const gridTemplate = `${isCheckboxShown ? '20px' : ''} ${columns.map((column) => `minmax(${column.width ?? '90px'} , 1fr)`).join(' ')}`;

  const isAllSelected = (): boolean => {
    return rows.every((row) => row.isDisabled || row.isSelected);
  };

  const isFirstColumnSticky = variant === TABLE_VARIANTS.FIRST_COLOMN_FIXED;

  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="border"
      borderRadius="4px"
      width="100%"
      sx={tableSx}
      overflow="hidden"
    >
      {rows.length > 0 && (
        <TableVirtuoso
          data={rows}
          style={{flexGrow: 1, overflowY: 'scroll', width: '100%'}}
          components={{
            Table: ({style, ...props}) => <table {...props} style={{...style, width: '100%'}} />
          }}
          fixedHeaderContent={() => (
            <Grid
              gap="8px"
              templateColumns={gridTemplate}
              borderBottom="1px solid"
              borderColor="border"
              bg="bg"
            >
              {isCheckboxShown && (
                <Flex pl="8px">
                  {!!onSelectAll && !!onUnselectAll && (
                    <Checkbox
                      checked={isAllSelected()}
                      onChange={() => {
                        if (isAllSelected()) {
                          onUnselectAll();
                        } else {
                          onSelectAll();
                        }
                      }}
                    />
                  )}
                </Flex>
              )}
              {columns.map((column, idx) => (
                <Flex
                  key={column.name}
                  textAlign={column.align ?? 'center'}
                  align="center"
                  justify={column.align === 'right' ? 'end' : 'start'}
                  borderRight={isFirstColumnSticky && idx === 0 ? '1px solid' : ''}
                  borderColor="border"
                  minH="24px"
                  position={idx === 0 && isFirstColumnSticky ? 'sticky' : 'static'}
                  left={idx === 0 && isFirstColumnSticky ? '0' : undefined}
                  bg="bg"
                  _last={{paddingRight: '8px'}}
                  _first={{
                    paddingLeft: '8px'
                  }}
                >
                  <Text fontSize="12px" color="bodyText">
                    {column.name}
                  </Text>
                </Flex>
              ))}
            </Grid>
          )}
          itemContent={(_, {key, values, isSelected, isDisabled}) => {
            return (
              <Grid gap="8px" templateColumns={gridTemplate} _last={{borderBottom: 'none'}}>
                {isCheckboxShown && (
                  <Flex pl="8px">
                    <Checkbox
                      checked={isSelected}
                      isDisabled={isDisabled}
                      onChange={(checked) => {
                        onSelect?.(key, checked);
                      }}
                    />
                  </Flex>
                )}

                {values.map((value, index) => (
                  <Flex
                    key={index}
                    className="table-column"
                    align="center"
                    h="24px"
                    overflow="hidden"
                    justifyContent={columns[index]?.align === 'right' ? 'end' : 'start'}
                    borderRight={isFirstColumnSticky && index === 0 ? '1px solid' : ''}
                    borderColor="border"
                    bg="bg"
                    position={index === 0 && isFirstColumnSticky ? 'sticky' : 'static'}
                    left={index === 0 && isFirstColumnSticky ? '0' : undefined}
                    _last={{paddingRight: '8px'}}
                    _first={{
                      paddingLeft: '8px'
                    }}
                  >
                    <Text
                      fontSize="12px"
                      color="bodyText"
                      opacity={isDisabled ? '0.5' : '1'}
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {value}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            );
          }}
        />
      )}

      {rows.length === 0 && (
        <Center h="60px" fontSize="12px" color="bodyText">
          {noItemsTitle}
        </Center>
      )}
    </Flex>
  );
};

export const VirtualTable = VirtualTableFC;
