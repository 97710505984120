import {FC, useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName} from '@progress-fe/ui-kit';

import {TElementModelJsonSchemasModel, TJsonSchemaModel} from 'core/models';
import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';
import {EModelType} from 'core/enums';

interface IProps {
  modelInstance: TElementModelJsonSchemasModel;
  onChangeDefaultModel: (modelType: EModelType, modelId: string) => void;
}

const ElementModelFormsFC: FC<IProps> = ({modelInstance, onChangeDefaultModel}) => {
  const {jsonSchemas, namePropValue} = modelInstance;

  const tabs: IJsonSchemaTab[] = useMemo(() => {
    return jsonSchemas.map((jsonSchema, index) => ({
      index: index,
      name: jsonSchema.name,
      isDisabled: jsonSchema.schema.readOnly,
      jsonSchema
    }));
  }, [jsonSchemas]);

  const checkDefaultModelChanged = (oldFormData: unknown, newFormData: unknown) => {
    // @ts-ignore
    const prevIsDefault = oldFormData.basic?.isDefaultModel || false;
    // @ts-ignore
    const nextIsDefault = newFormData.basic?.isDefaultModel || false;

    return nextIsDefault && !prevIsDefault;
  };

  const handleChangeFormData = (newFormData: unknown, jsonSchema: TJsonSchemaModel) => {
    const isDefaultModelChanged = checkDefaultModelChanged(jsonSchema.formData, newFormData);

    jsonSchema.updateFormData(newFormData);
    console.info('FormData:', newFormData);

    if (isDefaultModelChanged) {
      onChangeDefaultModel(modelInstance.type, modelInstance.id);
    }
  };

  return (
    <Box>
      <JsFormName name={namePropValue} />
      <JsTabsFormWrapper
        tabs={tabs}
        onChange={(formData, tabId: number) => {
          handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
        }}
      />
    </Box>
  );
};

export const ElementModelForms = observer(ElementModelFormsFC);
