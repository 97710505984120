import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Box,
  Grid,
  Step,
  Center,
  Stepper,
  StepTitle,
  StepIndicator,
  StepSeparator,
  useSteps
} from '@chakra-ui/react';
import {ResultTable, Svg, ISimpleTableColumn, ISimpleTableRow} from '@progress-fe/ui-kit';

import {TCalculationResultItemModel, TCalculationSliderResultModel} from 'core/models';

interface IProps {
  resultsInstance: TCalculationResultItemModel;
}

const SliderResultsFC: FC<IProps> = ({resultsInstance}) => {
  const initialData = resultsInstance.data as TCalculationSliderResultModel;

  const resultsCount = initialData.points.length || 0;

  const {activeStep, setActiveStep} = useSteps({index: 0, count: resultsCount});

  const steps = useMemo(() => {
    return initialData.points.map((point) => point.title);
  }, [initialData.points]);

  const columns: ISimpleTableColumn[] = useMemo(() => {
    return initialData.points[activeStep].columns.map((c, index) => ({
      name: c.title,
      columnTemplate: index === 0 ? '2fr' : '1fr',
      align: index === 0 ? 'left' : 'right'
    }));
  }, [activeStep, initialData.points]);

  const rows: ISimpleTableRow<string>[] = useMemo(() => {
    const rowsCount = initialData.points[activeStep].columns[0]?.values.length || 0;
    if (rowsCount === 0) return [];

    const rowsList: ISimpleTableRow<string>[] = [];
    for (let i = 0; i < rowsCount; i++) {
      rowsList.push({
        key: `${i}`,
        values: initialData.points[activeStep].columns.map(
          (c) => c.values[i]?.value?.toString() || ''
        )
      });
    }

    return rowsList;
  }, [activeStep, initialData.points]);

  return (
    <Box>
      <Box padding="20px 0 40px 0">
        <Stepper gap="0" size="m" index={activeStep} orientation="horizontal">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator onClick={() => setActiveStep(index)}>
                <Box
                  width="100%"
                  height="100%"
                  border="1px solid"
                  borderColor="white"
                  borderRadius="50%"
                  bg={index <= activeStep ? 'accent' : 'white'}
                />
              </StepIndicator>
              <StepTitle>
                <Box ml="-11px" w="30px" textAlign="center">
                  {step}
                </Box>
              </StepTitle>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>

      <Grid gridTemplateColumns="1fr 1fr">
        <ResultTable columns={columns} rows={rows} precision={3} />
        <Center>
          {activeStep === 0 && <Svg name="CokerResult_0" width={82} height={192} />}
          {activeStep === 1 && <Svg name="CokerResult_25" width={82} height={192} />}
          {activeStep === 2 && <Svg name="CokerResult_50" width={82} height={192} />}
          {activeStep === 3 && <Svg name="CokerResult_75" width={82} height={192} />}
          {activeStep === 4 && <Svg name="CokerResult_100" width={82} height={192} />}
        </Center>
      </Grid>
    </Box>
  );
};

export const SliderResults = observer(SliderResultsFC);
