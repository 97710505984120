import {FC, memo} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';

import {SvgMenu} from '../../menu';
import {ISvgMenuItem} from '../../../interfaces';

interface IProps {
  title: string;
  dropdownItems?: ISvgMenuItem<string>[];
  onSelectItem?: (value: string) => void;
}

const EntityHeaderFC: FC<IProps> = ({title, dropdownItems, onSelectItem}) => {
  return (
    <Flex justify="space-between" align="center" p="0 8px 0 0">
      <Text fontSize="md" fontWeight={700} p="8px" color="black">
        {title}
      </Text>
      {!!dropdownItems && !!onSelectItem && (
        <Box>
          <SvgMenu items={dropdownItems} onSelect={(value) => onSelectItem(value as string)} />
        </Box>
      )}
    </Flex>
  );
};

export const EntityHeader = memo(EntityHeaderFC);
