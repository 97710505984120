/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Args } from './Args';
import {
    ArgsFromJSON,
    ArgsFromJSONTyped,
    ArgsToJSON,
} from './Args';
import type { TechProcessActionType } from './TechProcessActionType';
import {
    TechProcessActionTypeFromJSON,
    TechProcessActionTypeFromJSONTyped,
    TechProcessActionTypeToJSON,
} from './TechProcessActionType';

/**
 * 
 * @export
 * @interface TechProcessAction
 */
export interface TechProcessAction {
    /**
     * 
     * @type {TechProcessActionType}
     * @memberof TechProcessAction
     */
    type: TechProcessActionType;
    /**
     * 
     * @type {Args}
     * @memberof TechProcessAction
     */
    args: Args;
    /**
     * 
     * @type {any}
     * @memberof TechProcessAction
     */
    structureJson?: any | null;
}

/**
 * Check if a given object implements the TechProcessAction interface.
 */
export function instanceOfTechProcessAction(value: object): value is TechProcessAction {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('args' in value) || value['args'] === undefined) return false;
    return true;
}

export function TechProcessActionFromJSON(json: any): TechProcessAction {
    return TechProcessActionFromJSONTyped(json, false);
}

export function TechProcessActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechProcessAction {
    if (json == null) {
        return json;
    }
    return {
        
        'type': TechProcessActionTypeFromJSON(json['type']),
        'args': ArgsFromJSON(json['args']),
        'structureJson': json['structure_json'] == null ? undefined : json['structure_json'],
    };
}

export function TechProcessActionToJSON(value?: TechProcessAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': TechProcessActionTypeToJSON(value['type']),
        'args': ArgsToJSON(value['args']),
        'structure_json': value['structureJson'],
    };
}

