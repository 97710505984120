import {observer} from 'mobx-react-lite';
import {Edge, Node, ReactFlowProvider} from '@xyflow/react';
import {FC, useCallback, useEffect, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box, Center, Flex, IconButton, Tab, TabList, Tabs} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';
import {
  IRFMenuItem,
  TRFEdgeDataConfig,
  TRFCalcDataConfig,
  TRFStructDataConfig
} from '@progress-fe/rf-core';

import {EProjectEntity} from 'core/enums';
import {TElementResultJsonSchemasModel} from 'core/models';
import {RFStructure, RFCalculation} from 'ui-kit';

import {DrawingResultsView} from './components';

interface IProps {
  selectedEntityId: string | null;
  selectedEntityType: EProjectEntity;
  structureMenuItems: IRFMenuItem[];
  structureNodes: Node<TRFStructDataConfig>[];
  structureEdges: Edge<TRFEdgeDataConfig>[];
  calculationNodes: Node<TRFCalcDataConfig>[];
  calculationEdges: Edge[];
  resultsInstances: TElementResultJsonSchemasModel[];
  onSelectEntity: (entityType: EProjectEntity) => void;
}

const TABS: {index: number; name: string; type: EProjectEntity}[] = [
  {index: 0, name: 'Структура', type: EProjectEntity.Settings},
  {index: 1, name: 'Расчёт', type: EProjectEntity.Task},
  {index: 2, name: 'Результаты', type: EProjectEntity.Result}
];

const MIN_RF_WIDTH_PX = 470;
const MIN_RF_MESSAGE_WIDTH_PX = 160;

const ProjectDrawingFC: FC<IProps> = ({
  selectedEntityId,
  selectedEntityType,
  structureNodes,
  structureEdges,
  structureMenuItems,
  calculationNodes,
  calculationEdges,
  resultsInstances,
  onSelectEntity
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const activeTab = TABS.find((tab) => tab.type === selectedEntityType) || TABS[0];
    setTabIndex(activeTab.index);
  }, [selectedEntityType]);

  const handleTabChange = useCallback(
    (index: number) => {
      const activeTab = TABS.find((tab) => tab.index === index) || TABS[0];
      onSelectEntity(activeTab.type);
    },
    [onSelectEntity]
  );

  return (
    <Box
      h="100%"
      bg="darkWhite"
      position={isExpanded ? 'absolute' : 'relative'}
      {...(isExpanded ? {top: 0, bottom: 0, left: 0, right: 0, zIndex: 10} : {})}
    >
      <AutoSizer>
        {({height, width}) => {
          // Space for rendering is not enough
          if (width < MIN_RF_WIDTH_PX) {
            return (
              <Center
                bg="darkWhite"
                width={`${width}px`}
                height={`${height}px`}
                flexDirection="column"
              >
                {width >= MIN_RF_MESSAGE_WIDTH_PX && (
                  <Box p="0 8px" textAlign="center">
                    <Box fontWeight={700}>Недостаточно места</Box>
                    <Box>Попробуйте растянуть область</Box>
                  </Box>
                )}
              </Center>
            );
          }

          return (
            <Box width={width}>
              <Tabs variant="solid-rounded" index={tabIndex} onChange={handleTabChange}>
                <Flex align="center" justify="center" p="12px 0 0 48px">
                  <TabList position="relative" zIndex={1}>
                    {TABS.map((tab) => (
                      <Tab key={tab.index}>{tab.name}</Tab>
                    ))}
                  </TabList>
                </Flex>
              </Tabs>

              <Box
                zIndex={1}
                top="12px"
                right="12px"
                position="absolute"
                borderRadius="4px"
                boxShadow="shadow_2"
              >
                <IconButton
                  size="sm"
                  aria-label=""
                  variant="ghostBg"
                  onClick={() => setIsExpanded(!isExpanded)}
                  icon={<Svg name={isExpanded ? 'Collapse' : 'Expand'} />}
                />
              </Box>

              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                {/* PROJECT STRUCTURE */}
                {tabIndex === 0 && (
                  <ReactFlowProvider>
                    <RFStructure
                      width={width}
                      height={height}
                      menuItems={structureMenuItems}
                      selectedEntityId={selectedEntityId}
                      selectedEntityType={selectedEntityType}
                      initialNodes={structureNodes}
                      initialEdges={structureEdges}
                    />
                  </ReactFlowProvider>
                )}

                {/* PROJECT CALCULATION */}
                {tabIndex === 1 && (
                  <RFCalculation
                    width={width}
                    height={height}
                    initialNodes={calculationNodes}
                    initialEdges={calculationEdges}
                  />
                )}

                {/* PROJECT RESULTS */}
                {tabIndex === 2 && (
                  <DrawingResultsView
                    height={height}
                    selectedEntityId={selectedEntityId}
                    resultsInstances={resultsInstances}
                  />
                )}
              </Box>
            </Box>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export const ProjectDrawing = observer(ProjectDrawingFC);
