import {cast, Instance, types} from 'mobx-state-tree';
import {Edge, Node} from '@xyflow/react';
import {TRFCalcDataConfig} from '@progress-fe/rf-core';

import {ResetModel} from 'core/models';
import {CALCULATION_DRAWING_LIST} from 'core/mocks/calcDrawing.mocks';

const CalculationDrawing = types
  .compose(
    ResetModel,
    types.model('CalculationDrawing', {
      nodes: types.optional(types.array(types.frozen<Node<TRFCalcDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge>()), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const drawingData = CALCULATION_DRAWING_LIST.find((d) => d.projectId === projectId);
      if (!drawingData) return;

      self.nodes = cast(drawingData.nodes);
      self.edges = cast(drawingData.edges);
    }
  }))
  .views(() => ({}));

export type TCalculationDrawingModel = Instance<typeof CalculationDrawing>;

export {CalculationDrawing};
