import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/rf-core';

export const UI_SCHEMA_COKER: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelId: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  },
  connections: {
    input_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    output_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    circular_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_COKER: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelId'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.CatCrackingElement, title: 'Реакторный блок УЗК'}],
          readOnly: true
        },
        modelId: {
          title: 'Модель',
          type: 'string',
          oneOf: [{const: 'D03B80FA-0000-0000-0000-000000000001', title: 'УЗК-1'}]
        }
      }
    },
    connections: {
      title: 'Связи',
      type: 'object',
      required: ['input_flow'],
      properties: {
        input_flow: {
          title: 'Входной материальный поток',
          type: 'string',
          oneOf: [{const: '704F784D-0000-0000-0000-000000000002', title: '1'}]
        },
        output_flow: {
          title: 'Выходной материальный поток',
          type: ['string', 'null'],
          readOnly: true,
          oneOf: [
            {const: null},
            {const: '704F784D-0000-0000-0000-000000000002', title: '1', readOnly: true}
          ]
        },
        circular_flow: {
          title: 'Рециркулирующий поток',
          type: ['string', 'null'],
          readOnly: true,
          oneOf: [
            {const: null},
            {const: '704F784D-0000-0000-0000-000000000002', title: '1', readOnly: true}
          ]
        }
      }
    }
  }
};

export const FORM_DATA_COKER = {
  basic: {
    name: 'COKER-100',
    elementType: EElement.CatCrackingElement,
    modelId: 'D03B80FA-0000-0000-0000-000000000001'
  },
  connections: {
    input_flow: '704F784D-0000-0000-0000-000000000002',
    output_flow: null,
    circular_flow: null
  }
};

export const REVERT_FORM_DATA = {};
