import {cast, Instance, types} from 'mobx-state-tree';
import {Edge, Node} from '@xyflow/react';
import {TRFEdgeDataConfig, TRFStructDataConfig} from '@progress-fe/rf-core';

import {ResetModel} from 'core/models';
import {STRUCTURE_DRAWING_LIST} from 'core/mocks/structDrawing.mocks';

const StructureDrawing = types
  .compose(
    ResetModel,
    types.model('StructureDrawing', {
      nodes: types.optional(types.array(types.frozen<Node<TRFStructDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge<TRFEdgeDataConfig>>()), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const drawingData = STRUCTURE_DRAWING_LIST.find((d) => d.projectId === projectId);
      if (!drawingData) return;

      self.nodes = cast(drawingData.nodes);
      self.edges = cast(drawingData.edges);
    }
  }))
  .views(() => ({}));

export type TStructureDrawingModel = Instance<typeof StructureDrawing>;

export {StructureDrawing};
