import {flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {IPureComponent} from '@progress-fe/core';

import {ResetModel} from 'core/models';

import {ElementsCatalog, PureComponentsCatalog} from './models';

const CatalogsStore = types
  .compose(
    ResetModel,
    types.model('CatalogsStore', {
      elementsCatalog: types.optional(ElementsCatalog, {}),
      pureComponentsCatalog: types.optional(PureComponentsCatalog, {})
    })
  )
  .actions((self) => ({
    load: flow(function* () {
      yield self.elementsCatalog.load();
      yield self.pureComponentsCatalog.load();
    })
  }))
  .views((self) => ({
    get pureComponents(): IPureComponent[] {
      return getSnapshot(self.pureComponentsCatalog.components);
    }
  }));

export type TCatalogsStoreModel = Instance<typeof CatalogsStore>;

export {CatalogsStore};
