import {FC, memo} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {Svg, TSvgName} from '@progress-fe/ui-kit';
import {RFSvg, TRFSvgName} from '@progress-fe/rf-core';

interface IProps {
  name: string;
  suffix?: string;
  icon?: TSvgName | null;
  elementIcon?: TRFSvgName | null;
  isActive?: boolean;
  onClick?: () => void;
}

const EntityItemFC: FC<IProps> = ({name, suffix, icon, elementIcon, isActive, onClick}) => {
  return (
    <Flex
      p="5px 8px"
      _hover={{bg: isActive ? 'accent' : 'accentMid', cursor: 'pointer'}}
      {...(isActive ? {bg: 'accent', color: 'white'} : {color: 'black'})}
      justify="space-between"
      onClick={() => onClick?.()}
    >
      <Flex gap="5px">
        {!!icon && <Svg name={icon} />}
        {!!elementIcon && <RFSvg name={elementIcon} />}
        <Box maxW={!!suffix ? '130px' : '230px'} className="cut-text">
          {name}
        </Box>
      </Flex>
      {!!suffix && <Box color={isActive ? 'accentMid' : 'accent'}>{suffix}</Box>}
    </Flex>
  );
};

export const EntityItem = memo(EntityItemFC);
