import {cast, flow, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {Paginator, ProjectInfo, RequestModel, ResetModel, TProjectInfoModel} from 'core/models';
import {PageProjectOut, ProjectsApi, ProjectTypeEnum, ResponseStatusProjectOut} from 'api';
import {EModuleType} from 'core/enums';
import {TProjectsMenu} from 'core/types';
import {ProjectsGetProjectsOrderByEnum, ProjectsGetProjectsRequest} from 'api/generated';
import {PROJECT_LIST} from 'core/mocks/projects.mocks';

import ProjectsFilter from './models/ProjectsFilter/ProjectsFilter';

const TypeFilters: Record<TProjectsMenu, ProjectsGetProjectsRequest> = {
  TechProcess: {type: ProjectTypeEnum.TechProcess},
  Mix: {type: ProjectTypeEnum.SamplesAndOilBlend},
  Bin: {deleted: true},
  Favorites: {favorites: true},
  Recent: {orderBy: ProjectsGetProjectsOrderByEnum.Desc, orderByField: 'updated_at'},
  Shared: {},
  All: {}
};

const MyProjectsStore = types
  .compose(
    ResetModel,
    types.model('MyProjectsStore', {
      projects: types.optional(types.array(ProjectInfo), []),
      projectsRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      filters: types.optional(ProjectsFilter, {}),
      paginator: types.optional(Paginator, {size: 50, page: 1, total: 0})
    })
  )
  .actions((self) => {
    const actions = {
      loadProjects: flow(function* () {
        //TODO: until favorites api will not be ready will set mock projects there
        if (self.filters.activeType === 'Favorites') {
          self.projects = cast(PROJECT_LIST);
          self.paginator.total = PROJECT_LIST.length;
          return;
        }

        const projects: PageProjectOut = yield self.projectsRequest.send(
          ProjectsApi.projectsGetProjects.bind(ProjectsApi),
          {
            ...TypeFilters[self.filters.activeType as TProjectsMenu],
            name: self.filters.query,
            page: self.paginator.page,
            size: self.paginator.size
          }
        );

        self.projects = cast(
          projects.items.map((project) => ({
            id: project.uuid,
            author: 'Иван Метанов',
            type: project.type,
            name: project.name,
            modified: project.updatedAt.toISOString(),
            // TODO: looks like useless property in MVP 2
            moduleType: EModuleType.SEPARATOR
          }))
        );

        self.paginator.total = projects.total || projects.items.length;
      }),

      findProjectById(id?: string | null): TProjectInfoModel | undefined {
        return self.projects.find((p) => p.id === id);
      },
      createProject: flow(function* (type: ProjectTypeEnum) {
        const response: ResponseStatusProjectOut = yield self.actionRequest.send(
          ProjectsApi.projectsCreateProject.bind(ProjectsApi),
          {
            projectInCreate: {
              name: `Новый проект (${self.projects.length + 1})`,
              type
            },
            idempotencyKey: uuidv4()
          }
        );

        const newProject = response.data;

        if (newProject) {
          self.projects.push({
            id: newProject.uuid,
            name: newProject.name,
            author: 'Иван Метанов',
            modified: newProject.updatedAt.toISOString(),
            type: newProject.type,
            moduleType: EModuleType.SEPARATOR
          });
        }

        return newProject;
      }),

      setActiveType: (type: TProjectsMenu) => {
        self.filters.activeType = type;
        self.paginator.resetModel();
        actions.loadProjects();
      },
      setPage: (page: number) => {
        self.paginator.page = page;
        actions.loadProjects();
      },
      setQuery: (query: string) => {
        self.filters.query = query;
        self.paginator.resetModel();
      }
    };
    return actions;
  })
  .views((self) => ({
    get isActionFails(): boolean {
      return self.actionRequest.isError;
    },
    get isLoading(): boolean {
      return self.projectsRequest.isPending;
    },
    get searchText(): string {
      return self.filters.query;
    },
    get activeMenu(): TProjectsMenu {
      return self.filters.activeType as TProjectsMenu;
    },
    get isNeedPagination(): boolean {
      return self.paginator.total > self.projects.length;
    }
  }));

export type TMyProjectsStoreStore = Instance<typeof MyProjectsStore>;

export {MyProjectsStore};
