/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DeleteModel } from './DeleteModel';
import {
    DeleteModelFromJSON,
    DeleteModelFromJSONTyped,
    DeleteModelToJSON,
} from './DeleteModel';
import type { UpdateElement } from './UpdateElement';
import {
    UpdateElementFromJSON,
    UpdateElementFromJSONTyped,
    UpdateElementToJSON,
} from './UpdateElement';
import type { RJFSchemas } from './RJFSchemas';
import {
    RJFSchemasFromJSON,
    RJFSchemasFromJSONTyped,
    RJFSchemasToJSON,
} from './RJFSchemas';
import type { NewElement } from './NewElement';
import {
    NewElementFromJSON,
    NewElementFromJSONTyped,
    NewElementToJSON,
} from './NewElement';
import type { NewModel } from './NewModel';
import {
    NewModelFromJSON,
    NewModelFromJSONTyped,
    NewModelToJSON,
} from './NewModel';
import type { UpdateModel } from './UpdateModel';
import {
    UpdateModelFromJSON,
    UpdateModelFromJSONTyped,
    UpdateModelToJSON,
} from './UpdateModel';
import type { DeleteElement } from './DeleteElement';
import {
    DeleteElementFromJSON,
    DeleteElementFromJSONTyped,
    DeleteElementToJSON,
} from './DeleteElement';
import type { ElementClassName } from './ElementClassName';
import {
    ElementClassNameFromJSON,
    ElementClassNameFromJSONTyped,
    ElementClassNameToJSON,
} from './ElementClassName';

/**
 * 
 * @export
 * @interface Args
 */
export interface Args {
    /**
     * 
     * @type {ElementClassName}
     * @memberof Args
     */
    type: ElementClassName;
    /**
     * 
     * @type {string}
     * @memberof Args
     */
    instanceUuid: string;
    /**
     * 
     * @type {{ [key: string]: RJFSchemas; }}
     * @memberof Args
     */
    rjsfSchemas: { [key: string]: RJFSchemas; };
    /**
     * 
     * @type {string}
     * @memberof Args
     */
    uuid: string;
}

/**
 * Check if a given object implements the Args interface.
 */
export function instanceOfArgs(value: object): value is Args {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('instanceUuid' in value) || value['instanceUuid'] === undefined) return false;
    if (!('rjsfSchemas' in value) || value['rjsfSchemas'] === undefined) return false;
    if (!('uuid' in value) || value['uuid'] === undefined) return false;
    return true;
}

export function ArgsFromJSON(json: any): Args {
    return ArgsFromJSONTyped(json, false);
}

export function ArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Args {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ElementClassNameFromJSON(json['type']),
        'instanceUuid': json['instance_uuid'],
        'rjsfSchemas': (mapValues(json['rjsf_schemas'], RJFSchemasFromJSON)),
        'uuid': json['uuid'],
    };
}

export function ArgsToJSON(value?: Args | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ElementClassNameToJSON(value['type']),
        'instance_uuid': value['instanceUuid'],
        'rjsf_schemas': (mapValues(value['rjsfSchemas'], RJFSchemasToJSON)),
        'uuid': value['uuid'],
    };
}

