import {memo} from 'react';
import {FieldProps} from '@rjsf/utils';
import {hasValue} from '@progress-fe/core';
import {Box, Button, Flex, Grid, GridItem, IconButton} from '@chakra-ui/react';

import {Svg} from '../../helpers';
import {JsFieldName} from '../../jsFormBase';
import {Checkbox, InputNumber} from '../../inputs';

type TFormDataConfig = {
  titleA: string;
  titleB: string;
  isCheckbox: boolean;
};

type TFormDataFieldValue = {
  checkboxValue: boolean;
  value: Array<{valueA: string | null; valueB: string | null}>;
};

type TFormData = {
  fieldConfig: TFormDataConfig;
  fieldValues: TFormDataFieldValue;
};

interface IProps extends FieldProps {}

const MAX_RAWS_AMOUNT = 5;

/**
 * Component renders "/schemas/jsf-probe-curves" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TFormData.
 */
const ProbeCurvesJsFieldFC = (props: IProps) => {
  const formData = props.formData as TFormData;

  const isCheckboxAvailable = formData.fieldConfig.isCheckbox;
  const isChecked = formData.fieldValues.checkboxValue;

  const rawsAmount = formData.fieldValues.value.length;
  const isDeleteRawDisabled =
    rawsAmount <= MAX_RAWS_AMOUNT || (isChecked && formData.fieldConfig.isCheckbox);

  const handleChangeCheckboxValue = (value: boolean) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        checkboxValue: value
      }
    });
  };

  const handleChangeValue = (value: Array<{valueA: string | null; valueB: string | null}>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        value: value
      }
    });
  };

  const handleChangeValueA = (index: number, valueA: string | null) => {
    const valueCopy = [...formData.fieldValues.value];
    valueCopy[index].valueA = valueA;
    handleChangeValue(valueCopy);
  };

  const handleChangeValueB = (index: number, valueB: string | null) => {
    const valueCopy = [...formData.fieldValues.value];
    valueCopy[index].valueB = valueB;
    handleChangeValue(valueCopy);
  };

  const handleAdd = () => {
    const valueCopy = [...formData.fieldValues.value];
    valueCopy.push({valueA: null, valueB: null});
    handleChangeValue(valueCopy);
  };

  const handleDelete = (index: number) => {
    const valueCopy = [...formData.fieldValues.value];
    valueCopy.splice(index, 1);
    handleChangeValue(valueCopy);
  };

  return (
    <Box data-testid="ProbeCurvesJsField-test">
      <Flex p="4px 0" flexDirection="column" gap="8px">
        {/* SHOW CHECKBOX IF IT NEEDS */}
        {isCheckboxAvailable && (
          <Grid gridTemplateColumns={`minmax(0, 180px) 1fr`} alignItems={'center'}>
            <JsFieldName name="Зависимая кривая" />
            <Checkbox
              checked={isChecked}
              isDisabled={props.schema.readOnly}
              onChange={handleChangeCheckboxValue}
            />
          </Grid>
        )}

        <Box p="4px 4px 1px 4px" borderRadius="4px" border="1px solid" borderColor="border">
          {/* LIST HEADER */}
          <Grid
            gap="4px"
            p="2px 0 6px 0"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="border"
            templateColumns="172px 1fr"
          >
            <GridItem p="0 0 0 2px">{formData.fieldConfig.titleA}</GridItem>
            <GridItem p="0 0 0 2px">{formData.fieldConfig.titleB}</GridItem>
          </Grid>

          {/* LIST ITEMS */}
          <Box p="2px 0">
            {formData.fieldValues.value.map((item, index) => (
              <Grid key={index} gap="4px" p="2px 0" templateColumns="172px 1fr 24px">
                <InputNumber
                  isFloat
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  value={item.valueA || undefined}
                  isInvalid={!hasValue(item.valueA)}
                  disabled={props.schema.readOnly || (isChecked && formData.fieldConfig.isCheckbox)}
                  onChange={(value) => handleChangeValueA(index, value)}
                />
                <InputNumber
                  isFloat
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  disabled={props.schema.readOnly}
                  value={item.valueB || undefined}
                  isInvalid={!hasValue(item.valueB)}
                  rightElement={props.schema.$comment ? <>{props.schema.$comment}</> : undefined}
                  onChange={(value) => handleChangeValueB(index, value)}
                />
                <IconButton
                  size="2sm"
                  aria-label=""
                  variant="ghost"
                  isDisabled={props.schema.readOnly || isDeleteRawDisabled}
                  icon={<Svg size="s12" name="Cross" />}
                  onClick={() => handleDelete(index)}
                />
              </Grid>
            ))}
          </Box>
        </Box>

        {/* ADDING A NEW RAW */}
        {!props.schema.readOnly && (!isChecked || !formData.fieldConfig.isCheckbox) && (
          <Button variant="ghost" size="2sm" width="100%" onClick={handleAdd}>
            Добавить точку
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export const ProbeCurvesJsField = memo(ProbeCurvesJsFieldFC);
