import {flow, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {EventEmitter} from '@progress-fe/core';

import {ProjectTypeEnum} from 'api';
import {EProjectEntity} from 'core/enums';
import {
  ProjectBase,
  TElementJsonSchemasModel,
  TElementModelJsonSchemasModel,
  TElementResultJsonSchemasModel
} from 'core/models';

import {
  ProjectDrawing,
  ProjectElementModels,
  ProjectElementResults,
  ProjectElements,
  ProjectSettings,
  ProjectTask
} from './models';

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      type: types.optional(types.literal(ProjectTypeEnum.TechProcess), ProjectTypeEnum.TechProcess),
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectElementModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectDrawing: types.optional(ProjectDrawing, {}),
      projectResults: types.optional(ProjectElementResults, {})
    })
  )
  .actions((self) => ({
    async runCalculation() {
      return Promise.resolve(false);
    },
    resetCalculatedFields(): void {
      console.info('[Project]: Resetting fields...');
      self.projectElements.resetCalculatedFieldsOfElements();
      self.projectResults.clearAllCalculationData();
      self.isResettingFieldsNeeded = false;
    }
  }))
  .actions((self) => ({
    _selectEntity(entityId: string): void {
      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EProjectEntity.Element, entityId);
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EProjectEntity.Model, entityId);
      } else if (self.projectResults.hasResult(entityId)) {
        self.uiState.select(EProjectEntity.Result, entityId);
      }
    },
    uninitialized() {
      EventEmitter.off('SelectEntity', this._selectEntity);
    }
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;

      const project = yield self.getProjectInfo(projectId);

      if (!project) {
        return;
      }

      self._baseInit(project);

      self.projectModels.fetch(project.uuid);
      self.projectElements.fetch(project.uuid);
      self.projectDrawing.fetch(project.uuid);
      self.projectResults.fetch(project.uuid);
      self.projectSettings.init(project.uuid);

      EventEmitter.on('SelectEntity', self._selectEntity);

      self.isLoading = false;
    })
  }))
  .views((self) => ({
    get selectedElement(): TElementJsonSchemasModel | null {
      if (self.uiState.entityType !== EProjectEntity.Element) return null;
      return self.projectElements.elements.find((el) => el.id === self.uiState.entityId) || null;
    },
    get selectedModel(): TElementModelJsonSchemasModel | null {
      if (self.uiState.entityType !== EProjectEntity.Model) return null;
      return self.projectModels.models.find((el) => el.id === self.uiState.entityId) || null;
    },
    get selectedResultSchema(): TElementResultJsonSchemasModel | null {
      if (self.uiState.entityType !== EProjectEntity.Result) return null;
      return (
        self.projectResults.elementsSchemasResults.find((el) => el.id === self.uiState.entityId) ||
        null
      );
    }
  }));

export type TTechProcessStore = Instance<typeof TechProcessStore>;

export type TTechProcessStoreSnapshotIn = SnapshotIn<typeof TechProcessStore>;

export {TechProcessStore};
