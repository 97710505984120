import {Box, BoxProps, Flex, IconButton} from '@chakra-ui/react';
import {FC, PropsWithChildren, ReactElement} from 'react';

import {Svg} from '../../Svg';

type TProps = {
  title: string;
  actionElement?: ReactElement;
  onClose?: () => void;
} & PropsWithChildren &
  BoxProps;

export const DialogWindow: FC<TProps> = ({
  title,
  children,
  actionElement,
  onClose,
  ...boxProps
}) => {
  return (
    <Box boxShadow="shadow_6" borderRadius="4px" bg="bg" p="12px" {...boxProps}>
      <Flex justifyContent="space-between" gap="24px">
        <Box fontWeight={700} fontSize="16px">
          {title}
        </Box>
        <Box>
          {!!actionElement && actionElement}
          <IconButton
            size="2sm"
            aria-label=""
            variant="ghostTr"
            icon={<Svg size="s12" name="Cross" />}
            onClick={onClose}
          />
        </Box>
      </Flex>
      <Box p="12px 0 0 0">{children}</Box>
    </Box>
  );
};
