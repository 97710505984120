import {FC, PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {Center} from '@chakra-ui/react';

import {useStore} from 'core/hooks';

interface IProps extends PropsWithChildren {}

const AppAuthFC: FC<IProps> = ({children}) => {
  const {authStore} = useStore();

  if (authStore.isFailed) {
    return (
      <Center bg="tomato" h="100px" color="white">
        Ошибка авторизации
      </Center>
    );
  }

  return <>{children}</>;
};

export const AppAuth = observer(AppAuthFC);
