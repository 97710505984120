import {memo} from 'react';
import {Box} from '@chakra-ui/react';
import {ChakraStylesConfig, Select as ChakraSelect, SingleValue} from 'chakra-react-select';

import {ISelectOption} from '../../../interfaces';

interface IProps<T> {
  size?: 'sm';
  name: string;
  value?: ISelectOption<T>;
  options: ISelectOption<T>[];
  placeholder?: string;
  isDisabled?: boolean | null;
  inPortal?: boolean;
  onChange: (value: SingleValue<ISelectOption<T>>) => void;
}

const SelectFC = <T,>({
  size = 'sm',
  name,
  value,
  options,
  placeholder,
  isDisabled,
  inPortal,
  onChange
}: IProps<T>) => {
  const chakraStyles: ChakraStylesConfig = {
    container: (provided, state) => ({
      ...provided,
      minH: '24px',
      h: '24px',
      opacity: state.isDisabled ? '0.6' : '1'
    }),
    control: (provided) => ({
      ...provided,
      minH: '24px',
      h: '24px',
      boxShadow: 'shadow_5',
      bg: 'buttonBg !important',
      _hover: {
        bg: 'buttonBg',
        cursor: 'pointer'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: '12px',
      p: '0 2px'
    }),
    inputContainer: (provided) => ({
      ...provided,
      p: 0
    }),
    placeholder: (provided) => ({
      ...provided,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      p: 0,
      m: 0,
      w: '28px',
      color: 'contrastIcon'
    }),
    menu: (provided) => ({
      ...provided,
      m: 0,
      p: '1px 0',
      zIndex: 3
    }),
    menuList: (provided) => ({
      ...provided,
      p: '2px',
      border: 'none',
      bg: 'buttonBg'
    }),
    option: (provided, state) => {
      const data = state.data as ISelectOption<T>;
      return {
        ...provided,
        display: 'block',
        p: '3px 12px',
        h: '24px',
        fontSize: '12px',
        borderRadius: '4px',
        bg: state.isSelected ? 'accent !important' : 'buttonBg',
        color: state.isSelected ? 'white' : 'bodyText',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxW: '100%',
        opacity: data.readonly ? '0.5' : '1',
        pointerEvents: data.readonly ? 'none' : 'all'
      };
    }
  };

  return (
    <ChakraSelect
      menuPortalTarget={inPortal ? document.body : undefined}
      useBasicStyles
      isSearchable={false}
      isDisabled={isDisabled || false}
      size={size}
      name={name}
      options={options}
      noOptionsMessage={() => <Box fontSize="11px">Пусто</Box>}
      closeMenuOnSelect
      placeholder={placeholder}
      value={value}
      chakraStyles={chakraStyles}
      // @ts-ignore
      onChange={onChange}
    />
  );
};

export const Select = memo(SelectFC);
