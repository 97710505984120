import {memo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {Box, Button, Flex, Grid} from '@chakra-ui/react';
import _ from 'lodash-es';

import {Select} from '../../inputs';
import {Dialog} from '../../helpers';
import {JsFieldName} from '../../jsFormBase';
import {ISelectOption} from '../../../interfaces';

import {
  SampleListDialog,
  CompositionHeader,
  CompositionTable,
  CompositionSummary
} from './components';

type TFormDataConfig = {
  optionsTitle: string;
  options: ISelectOption<string | number>[];
};

type TFormDataFieldValue = {
  optionValue: string | number;
  value: Array<{sampleId: string; sampleValue: string | null}>;
};

type TFormData = {
  fieldConfig: TFormDataConfig;
  fieldValues: TFormDataFieldValue;
};

interface IProps extends FieldProps {}

// TODO: Get from BE side
const SAMPLE_LIST_MOCK: Array<{sampleId: string; sampleName: string}> = [
  {sampleId: 'Sample-1A', sampleName: 'Sample-1AN'},
  {sampleId: 'Sample-1B', sampleName: 'Sample-1BN'},
  {sampleId: 'Sample-2F', sampleName: 'Sample-2FN'}
];

/**
 * Component renders "/schemas/jsf-sample-composition" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TFormData.
 */
const SampleCompositionJsFieldFC = (props: IProps) => {
  const [isDialog, setIsDialog] = useState(false);

  const formData = props.formData as TFormData;
  const isNoSamples = formData.fieldValues.value.length === 0;

  const handleChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        optionValue: option.value
      }
    });
  };

  const handleChangeValue = (value: Array<{sampleId: string; sampleValue: string | null}>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        value: value
      }
    });
  };

  const handleChangeSimpleValue = (index: number, sampleValue: string | null) => {
    const valueCopy = _.cloneDeep(formData.fieldValues.value);
    valueCopy[index].sampleValue = sampleValue;
    handleChangeValue(valueCopy);
  };

  const handleAddSimple = (sampleId: string) => {
    const valueCopy = [...formData.fieldValues.value];
    valueCopy.push({sampleId, sampleValue: null});
    handleChangeValue(valueCopy);
  };

  const handleDeleteSample = (sampleId: string) => {
    handleChangeValue(formData.fieldValues.value.filter((i) => i.sampleId !== sampleId));
  };

  const handleToggleDialog = () => {
    setIsDialog(!isDialog);
  };

  return (
    <Box data-testid="SampleCompositionJsField-test">
      <Flex p="4px 0" flexDirection="column" gap="8px">
        {/* SELECT FIELD */}
        <Grid gridTemplateColumns={`minmax(0, 180px) 1fr`} alignItems={'center'}>
          <JsFieldName name={formData.fieldConfig.optionsTitle} />
          <Select
            name={props.name}
            options={formData.fieldConfig.options}
            isDisabled={props.schema.readOnly}
            placeholder="Не выбрано"
            value={formData.fieldConfig.options.find(
              (opt) => opt.value === formData.fieldValues.optionValue
            )}
            onChange={(newValue) => {
              handleChangeFormOptionValue(newValue as ISelectOption<string | number>);
            }}
          />
        </Grid>

        <Box p="4px" borderRadius="4px" border="1px solid" borderColor="border">
          {isNoSamples ? (
            <Flex h="64px" alignItems="center" justifyContent="center">
              Нет проб
            </Flex>
          ) : (
            <Box>
              {/* LIST HEADER */}
              <CompositionHeader />
              {/* LIST OF SAMPLE ITEMS */}
              <CompositionTable
                value={formData.fieldValues.value}
                readOnly={props.schema.readOnly}
                suffix={props.schema.$comment}
                onChangeSimpleValue={handleChangeSimpleValue}
                onDeleteSample={handleDeleteSample}
              />
              {/* SUMMARY */}
              <CompositionSummary
                suffix={props.schema.$comment}
                value={formData.fieldValues.value}
              />
            </Box>
          )}
        </Box>

        {isDialog && (
          <Dialog title="Добавить пробы" isFloating onClose={handleToggleDialog}>
            <SampleListDialog
              sampleList={SAMPLE_LIST_MOCK}
              selectedSamplesIdx={formData.fieldValues.value.map((i) => i.sampleId)}
              onDeleteSample={handleDeleteSample}
              onAddSample={handleAddSimple}
            />
          </Dialog>
        )}

        {/* ADDING A NEW SAMPLE */}
        {!props.schema.readOnly && (
          <Button variant="ghost" size="2sm" width="100%" onClick={handleToggleDialog}>
            Добавить
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export const SampleCompositionJsField = memo(SampleCompositionJsFieldFC);
