import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';

import {EModelType, EProjectEntity} from 'core/enums';
import {
  TElementJsonSchemasModel,
  TElementModelJsonSchemasModel,
  TElementResultJsonSchemasModel
} from 'core/models';

import {ElementList, ElementModelList, ElementResultList, Settings, TaskList} from './components';

interface IProps {
  models: TElementModelJsonSchemasModel[];
  elements: TElementJsonSchemasModel[];
  results: TElementResultJsonSchemasModel[];
  selectedId: string | null;
  selectedType: EProjectEntity;
  onSelectEntity: (entityType: EProjectEntity, entityId?: string) => void;
  onCreateModel: (type: EModelType) => void;
}

const ProjectStructureFC: FC<IProps> = ({
  models,
  elements,
  results,
  selectedId,
  selectedType,
  onSelectEntity,
  onCreateModel
}) => {
  return (
    <Grid gridTemplateRows={'32px 8px 3fr 8px 2fr 8px 1fr 8px 2fr'} h="var(--height-layout)">
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={selectedType === EProjectEntity.Settings}
        onSelect={() => onSelectEntity(EProjectEntity.Settings)}
      />

      <FlexDivider />

      {/* PROJECT ELEMENTS */}
      <ElementList
        elements={elements}
        selectedId={selectedType === EProjectEntity.Element ? selectedId : null}
        onSelect={(elementId) => onSelectEntity(EProjectEntity.Element, elementId)}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS MODELS */}
      <ElementModelList
        models={models}
        selectedId={selectedType === EProjectEntity.Model ? selectedId : null}
        onSelect={(modelId) => onSelectEntity(EProjectEntity.Model, modelId)}
        onCreateModel={onCreateModel}
      />

      <FlexDivider withDots />

      {/* PROJECT TASKS */}
      <TaskList
        isSelected={selectedType === EProjectEntity.Task}
        onSelect={() => onSelectEntity(EProjectEntity.Task)}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS RESULTS */}
      <ElementResultList
        results={results}
        selectedId={selectedType === EProjectEntity.Result ? selectedId : null}
        onSelect={(resultId) => onSelectEntity(EProjectEntity.Result, resultId)}
      />
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);
